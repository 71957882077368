import { TableColumn } from 'components/table';
import Loader from 'components/table/Loader';
import Pagination from 'components/table/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAllCompany } from 'resources/serverReq/admin/company';
import { getAllMappedUserDevices } from 'resources/serverReq/admin/device';
import '../../common/common.css';
import '../../table/table.css';

export default function AllMappedUsers() {
    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(1);
    const [queryPatient, setQueryPatient] = useState('');
    const [queryDoctor, setQueryDoctor] = useState('');
    const [queryCompany, setQueryCompany] = useState('');
    const [queryDevice, setQueryDevice] = useState('');
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [companyId, setCompanyId] = useState(null);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        const params = {
            page: page,
            searchDevice: queryDevice,
            searchPatient: queryPatient,
            searchDoctor: queryDoctor,
            searchCompany: queryCompany,
            company_id: companyId
        };
        getAllCompany()
            .then(({ data }) => {
                const companies = data.data.companies.rows;
                let options = [];
                companies.map((val) => {
                    options.push({
                        value: val.id,
                        label: val.name
                    });
                });
                setCompanyOptions(options);
            })
            .catch((err) => {
                console.log('🚀 ~ file: Adherence.js ~ line 96 ~ useEffect ~ err', err);
            });
        getAllMappedUserDevices(params)
            .then((response) => {
                setIsLoading(true);
                setDataNotAvailable(false);
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.deviceMappings;
                    setUserData(res.rows);
                    setPageData({
                        count: res.count,
                        currentPage: res.currentPage,
                        totalPage: res.totalPage
                    });
                    if (res.count == 0) setDataNotAvailable(true);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setDataNotAvailable(false);
            });
    }, [queryPatient, queryDoctor, queryCompany, queryDevice, page]);

    async function blockCompany(id) {
        // await blockDeviceStatus(id).then((res) => {
        //     console.log("🚀 ~ file: Company.js ~ line 39 ~ awaitblockDeviceStatus ~ res.data", res.data)
        //     if (res.data.code === 200) {
        //         const userLists = userData.slice();
        //         for (const user of userLists) {
        //             if (user.id === id) {
        //                 user.status = res?.data?.data?.user?.status;
        //                 break;
        //             }
        //         }
        //         setUserData(userLists);
        //     }
        // }).catch(function (error) {
        //     console.log(error.response);
        // });
    }

    return (
        <>
         <div  style={{ display: 'flex'}}>
                            <input
                                onChange={(event) => setQueryPatient(event.target.value)}
                                type='text'
                                value={queryPatient}
                                className='form-control m-2'
                                placeholder='Search Patient'
                            />

                            <input
                                onChange={(event) => setQueryDoctor(event.target.value)}
                                type='text'
                                value={queryDoctor}
                                className='form-control m-2'
                                placeholder='Search Doctor'
                            />
                            <input
                                onChange={(event) => setQueryDevice(event.target.value)}
                                type='text'
                                value={queryDevice}
                                className='form-control m-2'
                                placeholder='Search Device'
                            />
                            <input
                                onChange={(event) => setQueryCompany(event.target.value)}
                                type='text'
                                value={queryCompany}
                                className='form-control m-2'
                                placeholder='Search Company'
                            />
         </div>
            {!isLoading ? (
                <div className='table-responsive'>
                    <div className='table-wrapper'>
                        <table className='table table-borderless text-center'>
                            <TableColumn
                                columns={[
                                    'Company Name',
                                    'Doctor Name',
                                    'Patient Name',
                                    'Device No.',
                                    'created On',
                                    'lastSync'
                                ]}
                            />
                            <tbody>
                                {dataNotAvailable ? (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>No Record Found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    userData.map((el) => (
                                        <tr key={el.id}>
                                            <td>
                                                <div className='d-flex flex-row bd-highlight p-2 '>
                                                    {/* <div className="d-flex align-items-start rounded-top">
                                                                <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                                    <img className="image-position symbol-round"
                                                                        src={
                                                                            "/assets/img/300_21.jpg"
                                                                        }
                                                                        alt="pic"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                    <span className='p-2'>{el.company.name}</span>
                                                </div>
                                            </td>
                                            <td>
                                                {el.doctor.firstName + ' ' + el.doctor.lastName}
                                            </td>
                                            <td>
                                                {el.patient.firstName + ' ' + el.patient.lastName}
                                            </td>
                                            <td>{el.device.serialNumber}</td>
                                            <td>{moment(el.createdAt).format('DD-MMM-YY')}</td>
                                            <td>
                                                {moment(el.lastSync)
                                                    .utc()
                                                    .format('DD-MMM-YY hh:mm a')}
                                            </td>
                                            {/* <td>

                                    <div className='d-flex'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <Link to={{
                                                pathname: `${SLUGS.viewMappedUser}`,
                                                state: el
                                            }}
                                                style={{ backgroundColor: "#006FD8", padding: "6px" }}
                                                className="btn btn-xs btn-sm text-light sm-btn svg-icon-button"
                                                title="Invite User text-info"

                                            >
                                                <span className="svg-icon menu-icon mr-2 text-light">
                                                    <IconDocument /> View Company Users
                                                </span>

                                            </Link>
                                        </div>
                                        
                                    </div>
                                </td> */}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination pageData={pageData} setPage={setPage} />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
