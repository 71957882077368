import React from 'react';

export default (props) => (
    <svg width="20" height="20" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_863:11453)">
            <path d="M12.9396 6.97373C12.9396 5.38059 12.3068 3.8527 11.1803 2.72619C10.0537 1.59967 8.52585 0.966797 6.93271 0.966797C5.33957 0.966797 3.81169 1.59967 2.68517 2.72619C1.55865 3.8527 0.925781 5.38059 0.925781 6.97373C0.925781 8.56686 1.55865 10.0947 2.68517 11.2213C3.81169 12.3478 5.33957 12.9807 6.93271 12.9807C8.52585 12.9807 10.0537 12.3478 11.1803 11.2213C12.3068 10.0947 12.9396 8.56686 12.9396 6.97373ZM11.7383 6.97373C11.7384 7.87302 11.4861 8.7543 11.0102 9.51735C10.5343 10.2804 9.85382 10.8946 9.04616 11.2901C8.2385 11.6856 7.33607 11.8466 6.44148 11.7546C5.5469 11.6627 4.69606 11.3216 3.98571 10.7701L10.7291 4.02673C11.361 4.84006 11.7383 5.86365 11.7383 6.97373ZM9.87971 3.17735L3.13633 9.92072C2.41758 8.99616 2.06119 7.84095 2.1341 6.67214C2.20701 5.50334 2.70421 4.40138 3.53228 3.5733C4.36036 2.74522 5.46232 2.24802 6.63113 2.17511C7.79993 2.1022 8.95514 2.4586 9.87971 3.17735Z" fill="#F39D5C" />
        </g>
        <defs>
            <clipPath id="clip0_863:11453">
                <rect width="12.0139" height="12.0139" fill="white" transform="translate(0.925781 0.966797)" />
            </clipPath>
        </defs>
    </svg>

);
