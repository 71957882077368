import { TableColumn, TableHeader, TableLastChild } from 'components/table'
import Loader from 'components/table/Loader'
import ViewBtn from 'components/table/ViewBtn'
import moment from "moment"
import React, { useEffect, useState } from 'react'
import { getAllCompanyUsers } from "resources/serverReq/company/user"
import SLUGS from 'resources/slugs'
import "../../common/common.css"
import '../../table/table.css'

export default function CompUsers(props) {
    let [userData, setUserData] = useState([]);
    const [totalPage, setTotalPage] = useState([]);
    const [perPageRows, setPerPageRows] = useState(7);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [status, setStatus] = useState("")
    const [state, setState] = useState("")
    const [dataNotAvailable, setDataNotAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const params = {
            page: page,
            search: query,

        };

        getAllCompanyUsers(params).then((response) => {
            setIsLoading(true)
            setDataNotAvailable(false)
            if (response?.data?.code == 200) {
                const res = response?.data?.data?.users
                setTotalPage(res.count)
                setUserData(res.rows)
                if (res.count == 0) setDataNotAvailable(true)

            }
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            setDataNotAvailable(false)

        })

    }, [status, query, state])

    return (
        <>
            {
                !isLoading ?
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">

                                <TableHeader setQuery={setQuery} query={query} />
                            </div>
                            <table className="table table-borderless text-center">
                                <TableColumn columns={["Name", "Email", "Gender", "Mobile", "City", "state", "Status", "Created On", "Actions"]} />
                                <tbody>
                                    {
                                        dataNotAvailable ? <tr><td></td><td></td><td></td><td></td><td>No Record Found</td><td></td><td></td></tr> :
                                            userData.map((el) => (
                                                <tr key={el.id}>
                                                    <td>
                                                        <div className="d-flex flex-row bd-highlight p-2 ">
                                                            {/* <div className="d-flex align-items-start rounded-top">
                                                                <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                                    <img className="image-position symbol-round"
                                                                        src={
                                                                            "/assets/img/300_21.jpg"
                                                                        }
                                                                        alt="pic"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <span className='p-2'>
                                                                {el.firstName + " " + el.lastName}

                                                            </span>
                                                        </div>

                                                    </td>

                                                    <td>{el.email}</td>
                                                    <td>{el.gender}</td>
                                                    <td>{el.phone}</td>
                                                    <td>{el.city}</td>
                                                    <td>{el.state}</td>
                                                    <td>
                                                        {
                                                            el.status == true ?
                                                                <span className='label label-lg label-light-success label-inline'>Active</span> :
                                                                <span className='label label-lg label-light-danger label-inline'>Inactive</span>
                                                        }
                                                    </td>
                                                    <td>{moment(el.createdAt).format('DD-MMM-YY hh:mm a')}</td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <ViewBtn path={SLUGS.viewCompanyUser} data={el} />

                                                            <TableLastChild editData={el} block={false} blockId={el.id} BlockCondition={el.status} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : <Loader />
            }
        </>
    )
}
