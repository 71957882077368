import { FormBtns } from "components/table";
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllCompany } from 'resources/serverReq/admin/company';
import { mapDevice } from "resources/serverReq/admin/device";
import { getAllUsers } from "resources/serverReq/admin/user";
import slugs from 'resources/slugs';
import * as Yup from "yup";
import "../../common/common.css";


export default function MapDevice(props) {

    const data = props?.location?.state
    const initialValues = {
        device_id: String(data.id),
        company_id: "",
    }

    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState([])
    const [doctorData, setDoctorData] = useState([])
    const companySchema = Yup.object().shape({


        company_id: Yup.string()
            .required("Required"),

    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    useEffect(() => {
        const params = {

            role: "doctor",

        };
        getAllUsers(params).then((response) => {
            if (response?.data?.code == 200) {
                const res = response?.data?.data.users
                setDoctorData(res.rows)

            }

        }).catch((err) => {
            console.log(err)

        })

        getAllCompany().then((response) => {
            if (response?.data?.code == 200) {
                const res = response?.data?.data?.companies
                setCompanyData(res.rows)

            }
        }).catch(err => {
            console.log("🚀 ~ file: AddPatient.js ~ line 73 ~ useEffect ~ err", err)
        });

    }, [])

    const formik = useFormik({
        initialValues,
        validationSchema: companySchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            mapDevice(values).then((res) => {

                if (res?.data?.code === 200) {
                    toast.success('Device has been mapped in system')
                    props.history.push(slugs.device)
                }
                disableLoading();
            })
                .catch((err) => {
                    console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage, {
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
                });
        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>

                    <h3 className='d-flex justify-content-center align-items-center form-title mt-5 mb-5'>Add Device Mapping</h3>
                    <div className="container-xl">
                        <form
                            onSubmit={formik.handleSubmit}

                        >

                            <div className="form-group form-input-2 mb-3">
                                <label>Device Name</label>
                                <input
                                    placeholder="Device Name"
                                    type="device_id"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "device_id"
                                    )}`}
                                    name="name"
                                    value={data.name}
                                />

                            </div>

                            <div className="form-group ">
                                <label >Company</label>
                                <select placeholder="Company"
                                    type="company_id"
                                    className={`select-input form-control-solid ${getInputClasses(
                                        "company_id"
                                    )} p-2`}
                                    name="company_id"
                                    {...formik.getFieldProps("company_id")}
                                >
                                    <option >Select..</option>
                                    {companyData.map((el) => (
                                        <option key={el.id} value={el.id} >{el.name}</option>
                                    ))}
                                </select>
                                {formik.touched.company_id && formik.errors.company_id ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.company_id}</div>
                                    </div>
                                ) : null}

                            </div>

                            {/* <div className="form-group ">
                                <label >Doctor</label>
                                <select placeholder="Doctor"
                                    type="doctor_id"
                                    className={`select-input form-control-solid ${getInputClasses(
                                        "doctor_id"
                                    )} p-2`}
                                    name="doctor_id"
                                    {...formik.getFieldProps("doctor_id")}
                                >
                                    <option >Select..</option>
                                    {doctorData.map((el) => (
                                        <option key={el.id} value={el.id} >{el.firstName + " " + el.lastName}</option>
                                    ))}
                                </select>
                                {formik.touched.doctor_id && formik.errors.doctor_id ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.doctor_id}</div>
                                    </div>
                                ) : null}

                            </div> */}
                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.device} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

