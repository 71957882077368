import { FormBtns } from 'components/table';
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllCompany } from 'resources/serverReq/admin/company';
import { updateUserInfo } from "resources/serverReq/admin/user";
import { getStates } from "resources/serverReq/common";
import slugs from 'resources/slugs';
import * as Yup from "yup";

export default function EditDoctor(props) {
    const data = props?.location?.state
    const initialValues = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dob: data.dob,
        gender: data.gender,
        phone: data.phone,
        city: data.city,
        role: "doctor",
        state: data.state,
        reference_code: data.reference_code,
        company_id: data.company?.id
    };
    const [loading, setLoading] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    const userSchema = Yup.object().shape({

        firstName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        lastName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        dob: Yup.date()
            .required("Required"),
        city: Yup.string()
            .required("Required"),
        role: Yup.string()
            .required("Required"),
        gender: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        phone: Yup.number()
            .min(10, "Minimum 10 symbols")
            .required("Required"),
        reference_code: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };
    useEffect(() => {
        getAllCompany()
        .then(({ data }) => {
            const companies = data.data.companies.rows;
            let options = [];
                companies.map((val) => {
                    options.push({
                        value: val.id,
                        label: val.name
                    });
                });
            setCompanyOptions(options);
        })
        .catch((err) => {
            console.log("🚀 ~ file: Adherence.js ~ line 96 ~ useEffect ~ err", err)
        });
        getStates().then(({ data }) => {
            const states = data.data.states;
            let options = []
            states.map(val => {
                options.push({
                    value: val.name,
                    label: val.name
                })
            });
            setStateOptions(options);
        }).catch(err => {
            console.log("🚀 ~ file: AddPatient.js ~ line 73 ~ useEffect ~ err", err)
        });


    }, [])

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: userSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();

            updateUserInfo(data.id, values).then((res) => {
                if (res?.data?.code === 200) {
                    toast.success('Doctor edited in system')
                    props.history.push(slugs.doctor)
                }
                disableLoading();
            })
                .catch((err) => {
                    console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage);
                });
        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <h3 className='d-flex justify-content-center align-items-center mt-5 mb-5'>Edit Doctor</h3>
                    <div className="d-flex justify-content-center align-items-center container-xl">
                        <form
                            onSubmit={formik.handleSubmit}
                        >
                            <div className='d-flex'>

                                <div className="form-group mb-3">
                                    <label>First Name</label>
                                    <input
                                        placeholder="First Name"
                                        type="firstName"
                                        className={`form-control form-control-solid ${getInputClasses(
                                            "firstName"
                                        )}`}
                                        name="firstName"
                                        {...formik.getFieldProps("firstName")}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.firstName}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group mb-3">
                                    <label>Last Name</label>
                                    <input
                                        placeholder="Last Name"
                                        type="lastName"
                                        className={`form-control form-control-solid ${getInputClasses(
                                            "lastName"
                                        )}`}
                                        name="lastName"
                                        {...formik.getFieldProps("lastName")}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.lastName}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Email</label>
                                <input
                                    placeholder="Email"
                                    type="email"
                                    className={`form-control form-control-solid ${getInputClasses(
                                        "email"
                                    )}`}
                                    name="email"
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.email}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className='d-flex'>
                                <div className="form-group mb-3">
                                    <label>Gender</label>
                                    <input
                                        placeholder="Gender"
                                        type="gender"
                                        className={`form-control form-control-solid ${getInputClasses(
                                            "gender"
                                        )}`}
                                        name="gender"
                                        {...formik.getFieldProps("gender")}
                                    />
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.gender}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group mb-3">
                                    <label>Date of Birth</label>
                                    <input
                                        placeholder="Date of Birth"
                                        type="date"
                                        className={`form-control form-control-solid ${getInputClasses(
                                            "dob"
                                        )}`}
                                        name="dob"
                                        {...formik.getFieldProps("dob")}
                                    />
                                    {formik.touched.dob && formik.errors.dob ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.dob}</div>
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                            <div className='d-flex'>

                                <div className="form-group mb-3">
                                    <label>Phone</label>
                                    <input
                                        placeholder="Phone"
                                        type="text" pattern="\d*" maxLength={10}
                                        className={`form-control form-control-solid ${getInputClasses(
                                            "phone"
                                        )}`}
                                        name="phone"
                                        {...formik.getFieldProps("phone")}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.phone}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group mb-3">
                                    <label>City</label>
                                    <input
                                        placeholder="City"
                                        type="city"
                                        className={`form-control form-control-solid ${getInputClasses(
                                            "city"
                                        )}`}
                                        name="city"
                                        {...formik.getFieldProps("city")}
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.city}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className="form-group w-50">
                                    <label >State</label>
                                    <select placeholder="State"
                                        type="state"
                                        className={`select-input form-control-solid ${getInputClasses(
                                            "state"
                                        )} p-2`}
                                        name="state"
                                        {...formik.getFieldProps("state")}
                                    >
                                        <option >Select..</option>
                                        {stateOptions.map((el) => (
                                            <option key={el.value} value={el.value} >{el.value}</option>
                                        ))}
                                    </select>
                                    {formik.touched.state && formik.errors.state ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.state}</div>
                                        </div>
                                    ) : null}

                                </div>
                                <div className="form-group form-input-2 mb-3">
                                    <label>Reference Code</label>
                                    <input
                                        placeholder="Reference Code"
                                        type="reference_code"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "reference_code"
                                        )}`}
                                        name="reference_code"
                                        {...formik.getFieldProps("reference_code")}
                                    />
                                    {formik.touched.reference_code && formik.errors.reference_code ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.reference_code}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='d-flex'>
                            <div className="form-group w-50">
                                <label >Company</label>
                                <select placeholder="Company id"
                                    type="company_id"
                                    className={`select-input form-control-solid ${getInputClasses(
                                        "company_id"
                                    )} p-2`}
                                    name="company_id"
                                    {...formik.getFieldProps("company_id")}
                                >
                                    <option >Select company</option>
                                    {companyOptions.map((el) => (
                                        <option key={el.value} value={el.value} >{el.label}</option>
                                    ))}
                                </select>
                            </div>
                            </div>

                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.doctor} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

