import { IconEdit } from 'assets/icons'
import { TableColumn, TableHeader } from 'components/table'
import Loader from 'components/table/Loader'
import Pagination from 'components/table/Pagination'
import moment from "moment"
import React, { useEffect, useState } from 'react'
import { FaEye } from "react-icons/fa"
import { Link } from 'react-router-dom'
import { closeSlot, getAllSlot } from 'resources/serverReq/admin/slot'
import SLUGS from 'resources/slugs'
import "../../common/common.css"
import '../../table/table.css'

export default function Slot() {


    const [slotData, setSlotData] = useState([])
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const params = {
            page: page,
            search: query

        };

        getAllSlot(params).then((response) => {
            setIsLoading(true)
            setDataNotAvailable(false)
            if (response?.data?.code == 200) {
                const res = response?.data?.data.slots
                console.log("🚀 ~ file: Slot.js ~ line 33 ~ getAllSlot ~ res", res)
                setSlotData(res.rows)
                setPageData({ count: res.count, currentPage: res.currentPage, totalPage: res.totalPage })
                if (res.count == 0) setDataNotAvailable(true)
                console.log("🚀 ~ file: Slot.js ~ line 36 ~ getAllSlot ~ res.length", res.length)

            }
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            setDataNotAvailable(false)
        })

    }, [query, page])

    async function blockSlot(id) {
        await closeSlot(id).then((res) => {

            if (res.data.code === 200) {
                const slotList = slotData.slice();
                for (const slot of slotList) {
                    if (slot.id === id) {
                        slot.status = res.data.data.status;

                        break;
                    }
                }
                setSlotData(slotList);
            }
        }).catch(function (error) {
            console.log(error.response);
        });
    }

    return (
        <>
            {
                !isLoading ?
                    <div className="table-responsive">
                        <div className="table-wrapper">

                            <TableHeader btnTitle={"+ Add Slot"} route={SLUGS.addSlot} setQuery={setQuery} query={query} />

                            <table className="table table-borderless text-center">
                                <TableColumn columns={["Name", "Display Name", "Display Type", "Start Time", "End Time", "Order", "type", "Created On", "Actions"]} />

                                <tbody>
                                    {
                                        dataNotAvailable ? <tr><td></td><td></td><td></td><td>No Record Found</td><td></td><td></td></tr> :
                                            slotData.map((el) => (
                                                <tr key={el.id}>
                                                    <td>
                                                        <div className="d-flex flex-row bd-highlight p-2 ">
                                                            {/* <div className="d-flex align-items-start rounded-top">
                                                                <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                                    <img className="image-position symbol-round"
                                                                        src={
                                                                            "/assets/img/300_21.jpg"
                                                                        }
                                                                        alt="pic"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <span className='p-2'>
                                                                {el.name}

                                                            </span>
                                                        </div>

                                                    </td>

                                                    <td >{el.displayName}</td>
                                                    <td>{el.displayType}</td>
                                                    <td>{el.startTime}</td>
                                                    <td>{el.endTime}</td>
                                                    <td>{el.order}</td>
                                                    <td>{el.type}</td>
                                                    <td>{moment(el.createdAt).format('DD-MMM-YY hh:mm a')}</td>
                                                    <td>
                                                        <div className='d-flex justify-content-start last-child-table'>


                                                            <Link to={{
                                                                pathname: `${SLUGS.viewSlot}/${el.id}`,
                                                            }}
                                                                style={{ padding: "6px", marginRight: "6px" }}
                                                                className="btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button"
                                                                title="View details"

                                                            >
                                                                <span className="svg-icon menu-icon mr-2 text-light">
                                                                    <FaEye style={{ color: 'black' }} />
                                                                </span>

                                                            </Link>


                                                            <Link to={{
                                                                pathname: `${SLUGS.editSlot}`,
                                                                state: el

                                                            }}
                                                                title="Edit"
                                                                className="btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button "

                                                            >
                                                                <span className="svg-icon svg-icon-sm svg-icon-primary">
                                                                    <IconEdit />
                                                                </span>
                                                            </Link>



                                                        </div>


                                                    </td>
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                            <Pagination pageData={pageData} setPage={setPage} />

                        </div>
                    </div>
                    : <Loader />
            }
        </>
    )
}
