import { FormBtns } from 'components/table';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { addDeviceInfo } from 'resources/serverReq/admin/device';
import slugs from 'resources/slugs';
import * as Yup from 'yup';
import '../../common/common.css';

const initialValues = {
    name: '',
    description: '',
    serialNumber: '',
    firmwareVersion: ''
};

export default function AddDevice(props) {
    const [loading, setLoading] = useState(false);
    const deviceSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Required'),
        description: Yup.string()
            .min(10, 'Minimum 10 symbols')
            .max(150, 'Maximum 150 symbols')
            .required('Required'),
        serialNumber: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Required'),
        firmwareVersion: Yup.string().required('Required').max(7).min(7)
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return 'is-invalid';
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return 'is-valid';
        }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: deviceSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            addDeviceInfo(values)
                .then((res) => {
                    if (res?.data?.code === 200) {
                        toast.success('New Device added in system');
                        props.history.push(slugs.device);
                    }
                    disableLoading();
                })
                .catch((err) => {
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage, {
                        closeOnClick: true,
                        hideProgressBar: false
                    });
                });
        }
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <h3 className='d-flex justify-content-center align-items-center form-title mb-5'>
                        Add New Device
                    </h3>
                    <div className='container-xl'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='form-group form-input-2 mb-3'>
                                <label>Device Name</label>
                                <input
                                    placeholder='Device Name'
                                    type='name'
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        'name'
                                    )}`}
                                    name='name'
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className='form-group form-input-2 mb-3'>
                                <label>Serial Number</label>
                                <input
                                    placeholder='Serial Number'
                                    type='serialNumber'
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        'serialNumber'
                                    )}`}
                                    name='serialNumber'
                                    {...formik.getFieldProps('serialNumber')}
                                />
                                {formik.touched.serialNumber && formik.errors.serialNumber ? (
                                    <div>
                                        <div className='fv-help-block'>
                                            {formik.errors.serialNumber}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className='form-group form-input-2 mb-3'>
                                <label>Firmware Version</label>
                                <input
                                    placeholder='X.X.X.X'
                                    type='firmwareVersion'
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        'firmwareVersion'
                                    )}`}
                                    name='firmwareVersion'
                                    {...formik.getFieldProps('firmwareVersion')}
                                />
                                {formik.touched.firmwareVersion && formik.errors.firmwareVersion ? (
                                    <div>
                                        <div className='fv-help-block'>
                                            {formik.errors.firmwareVersion}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className='form-group form-input-2 mb-3'>
                                <label>Device Description</label>
                                <textarea
                                    placeholder='description...'
                                    rows={3}
                                    cols={3}
                                    type='description'
                                    className={`form-control form-control-solid ${getInputClasses(
                                        'description'
                                    )}`}
                                    name='description'
                                    {...formik.getFieldProps('description')}
                                />
                                {formik.touched.description && formik.errors.description ? (
                                    <div>
                                        <div className='fv-help-block'>
                                            {formik.errors.description}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.device} />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
