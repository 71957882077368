import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FormBtns } from 'components/table';
import slugs from 'resources/slugs';
import { getAllMedicine } from 'resources/serverReq/admin/medicine';
import {
    scheduleDoseByAdmin,
    getUserSlotTimings,
    setDoseTimeByAdmin
} from 'resources/serverReq/admin/user';
import Loader from 'components/table/Loader';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import moment from 'moment';

export default function SchedulePatient(props) {
    const data = props?.location?.state;
    const history = useHistory();
    const [medicineData, setMedicineData] = useState([]);
    const [slotTiming, setSlotTimings] = useState(false);
    const [timingss, setTimingss] = useState([]);
    const [selectedMedicine, setSelectedMedicine] = useState(null);
    const [selectedDays, setSelectedDays] = useState([
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const initialTimings = {
        Morning: {
            beforeMeals: false,
            afterMeals: false,
            none: true,
            quantities: { beforeMeals: 1, afterMeals: 1 },
            values: { beforeMeals: 1, afterMeals: 2 }
        },
        Afternoon: {
            beforeMeals: false,
            afterMeals: false,
            none: true,
            quantities: { beforeMeals: 1, afterMeals: 1 },
            values: { beforeMeals: 3, afterMeals: 4 }
        },
        Evening: {
            beforeMeals: false,
            afterMeals: false,
            none: true,
            quantities: { beforeMeals: 1, afterMeals: 1 },
            values: { beforeMeals: 5, afterMeals: 6 }
        },
        Night: {
            beforeMeals: false,
            afterMeals: false,
            none: true,
            quantities: { beforeMeals: 1, afterMeals: 1 },
            values: { beforeMeals: 7, afterMeals: 8 }
        }
    };
    const [selectedOptions, setSelectedOptions] = useState(initialTimings);
    const timings = {
        Morning: {
            beforeMeals: { label: 'Before Meals', value: 1, quantity: 0 },
            afterMeals: { label: 'After Meals', value: 2, quantity: 0 },
            none: { label: 'None' }
        },
        Afternoon: {
            beforeMeals: { label: 'Before Meals', value: 1, quantity: 0 },
            afterMeals: { label: 'After Meals', value: 2, quantity: 0 },
            none: { label: 'None' }
        },
        Evening: {
            beforeMeals: { label: 'Before Meals', value: 1, quantity: 0 },
            afterMeals: { label: 'After Meals', value: 2, quantity: 0 },
            none: { label: 'None' }
        },
        Night: {
            beforeMeals: { label: 'Before Meals', value: 1, quantity: 0 },
            afterMeals: { label: 'After Meals', value: 2, quantity: 0 },
            none: { label: 'None' }
        }
    };

    const DoseTimings = () => {
        const handleCheckboxChange = (timing, option) => {
            setSelectedOptions((prevState) => {
                const newOptions = { ...prevState };
                if (option === 'none') {
                    newOptions[timing] = {
                        beforeMeals: false,
                        afterMeals: false,
                        none: true,
                        quantities: { beforeMeals: 0, afterMeals: 0 }
                    };
                } else {
                    newOptions[timing][option] = !prevState[timing][option];
                    if (newOptions[timing][option]) {
                        newOptions[timing].none = false;
                    } else if (!newOptions[timing].beforeMeals && !newOptions[timing].afterMeals) {
                        newOptions[timing].none = true;
                    }
                }
                return newOptions;
            });
        };

        const handleQuantityChange = (timing, option, change) => {
            setSelectedOptions((prevState) => {
                const newOptions = { ...prevState };
                newOptions[timing].quantities[option] += change;
                if (newOptions[timing].quantities[option] < 0) {
                    newOptions[timing].quantities[option] = 0;
                }
                return newOptions;
            });
        };

        return (
            <div className=''>
                <div className='form-group'>
                    <label className='fw-bold h5'>Configure Dose Timings</label>
                    {Object.keys(timings).map((timing) => (
                        <div key={timing} className='m-3'>
                            <div className='fw-bold text-success'>{timing}</div>
                            <div className='d-flex flex-wrap'>
                                {Object.keys(timings[timing]).map((option) => (
                                    <div key={option} className='form-check ms-3 mb-2'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id={`${timing}-${option}`}
                                            checked={selectedOptions[timing][option]}
                                            onChange={() => handleCheckboxChange(timing, option)}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor={`${timing}-${option}`}
                                        >
                                            {timings[timing][option].label}
                                        </label>
                                        {option !== 'none' && selectedOptions[timing][option] && (
                                            <span className='ms-2 d-flex align-items-center'>
                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-outline-secondary'
                                                    onClick={() =>
                                                        handleQuantityChange(timing, option, -1)
                                                    }
                                                >
                                                    -
                                                </button>
                                                <span className='mx-2'>
                                                    {selectedOptions[timing].quantities[option]}
                                                </span>
                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-outline-secondary'
                                                    onClick={() =>
                                                        handleQuantityChange(timing, option, 1)
                                                    }
                                                >
                                                    +
                                                </button>
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (query) {
            const params = { search: query };
            getAllMedicine(params)
                .then((response) => {
                    if (response?.data?.code === 200) {
                        setMedicineData(response.data.data.medicines.rows);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setMedicineData([]);
        }
    }, [query]);

    useEffect(() => {
        getUserSlotTimings(data.id)
            .then((response) => {
                if (response?.data?.code == 200) {
                    const res = response?.data?.data?.slots?.rows;
                    setTimingss(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [slotTiming]);

    const handleTimeChange = (id, newTime) => {
        const timingToUpdate = timingss.find((timing) => timing.id === id);
        const { startTime, endTime } = timingToUpdate;
        const formattedNewTime = moment(newTime, 'h:mm A');
        const formattedStartTime = moment(startTime, 'h:mm A');
        const formattedEndTime = moment(endTime, 'h:mm A');

        if (formattedNewTime.isBetween(formattedStartTime, formattedEndTime, null, '[]')) {
            const formattedTime = formattedNewTime.format('HH:mm');
            setTimingss((prevTimings) =>
                prevTimings.map((timing) =>
                    timing.id === id ? { ...timing, time: formattedTime } : timing
                )
            );
        } else {
            toast.error('Selected time is outside the allowed range');
        }
    };

    const renderTimings = (data) => {
        const timePeriods = ['Morning', 'Afternoon', 'Evening', 'Night'];

        return timePeriods.map((period) => {
            const beforeMeals = data.filter(
                (timing) => timing.name === period && timing.type === 'before'
            );
            const afterMeals = data.filter(
                (timing) => timing.name === period && timing.type === 'after'
            );

            return (
                <div key={period} className='mb-4 mt-4'>
                    <h4>{period}</h4>
                    <div className='d-flex justify-content-between'>
                        <div className='me-5'>
                            <h6>Before Meals</h6>
                            {beforeMeals.map((timing) => (
                                <div key={timing.id} className='d-flex mb-2 align-items-center'>
                                    <div className='d-flex align-items-center me-2'>
                                        <TimePicker
                                            clearIcon={null}
                                            onChange={(time) => handleTimeChange(timing.id, time)}
                                            value={
                                                timing.time ||
                                                (timing.startTime.includes('AM') ||
                                                timing.startTime.includes('PM')
                                                    ? moment(timing.startTime, 'h:mm A').format(
                                                          'HH:mm'
                                                      )
                                                    : timing.startTime)
                                            }
                                            format='HH:mm'
                                            disableClock={false}
                                            renderInput={(props) => (
                                                <input
                                                    {...props}
                                                    readOnly
                                                    style={{ cursor: 'not-allowed' }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <span className='me-2'>- {timing.endTime}</span>
                                </div>
                            ))}
                        </div>
                        <div className='ms-5'>
                            <h6>After Meals</h6>
                            {afterMeals.map((timing) => (
                                <div key={timing.id} className='d-flex mb-2 align-items-center'>
                                    <div className='d-flex align-items-center me-2'>
                                        <TimePicker
                                            clearIcon={null}
                                            onChange={(time) => handleTimeChange(timing.id, time)}
                                            value={
                                                timing.time ||
                                                (timing.startTime.includes('AM') ||
                                                timing.startTime.includes('PM')
                                                    ? moment(timing.startTime, 'h:mm A').format(
                                                          'HH:mm'
                                                      )
                                                    : timing.startTime)
                                            }
                                            format='HH:mm'
                                            disableClock={false}
                                            renderInput={(props) => (
                                                <input
                                                    {...props}
                                                    readOnly
                                                    style={{ cursor: 'not-allowed' }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <span className='me-2'>- {timing.endTime}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const handleTimings = () => {
        const newData = timingss.map((timing) => ({
            slot_id: timing.id,
            time: timing.time || moment(timing.startTime, 'h:mm A').format('HH:mm'),
            user_id: data.id
        }));
        const json = { data: newData };
        setDoseTimeByAdmin(json)
            .then((response) => {
                if (response?.data?.code == 200) {
                    toast.success('Timings updated successfully');
                    setSlotTimings(!slotTiming);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Something went wrong');
            });
    };

    const handleMedicineSelect = (medicine) => {
        setSelectedMedicine(medicine);
        setQuery('');
    };

    const handleDaySelection = (day) => {
        const newSelectedDays = selectedDays.includes(day)
            ? selectedDays.filter((d) => d !== day)
            : [...selectedDays, day];
        setSelectedDays(newSelectedDays);
    };

    const renderDayCheckboxes = () => {
        const days = {
            Sun: 'Sunday',
            Mon: 'Monday',
            Tue: 'Tuesday',
            Wed: 'Wednesday',
            Thu: 'Thursday',
            Fri: 'Friday',
            Sat: 'Saturday'
        };

        return (
            <div className='my-3'>
                <div className='row'>
                    {Object.keys(days).map((key) => {
                        const dayName = days[key];
                        return (
                            <div key={key} className='col-md-4 mb-3'>
                                <div className='form-check'>
                                    <input
                                        type='checkbox'
                                        className='form-check-input'
                                        id={key}
                                        checked={selectedDays.includes(key)}
                                        onChange={() => handleDaySelection(key)}
                                    />
                                    <label className='form-check-label' htmlFor={key}>
                                        {dayName}
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const findSlot = () => {
        let slots = [];
        Object.values(selectedOptions).forEach((option) => {
            if (option.none != true) {
                if (option.beforeMeals) {
                    slots.push(option.values.beforeMeals);
                }
                if (option.afterMeals) {
                    slots.push(option.values.afterMeals);
                }
            }
        });

        return slots;
    };

    const checkTime = () => {
        let count = 0;
        Object.values(selectedOptions).forEach((option) => {
            if (option.none != true) {
                count++;
            }
        });
        return count;
    };

    const count_morning = () => {
        let count = [];
        const option = selectedOptions['Morning'];
        if (option.none != true) {
            if (option.beforeMeals) {
                count.push({ 1: option.quantities.beforeMeals });
            }
            if (option.afterMeals) {
                count.push({ 2: option.quantities.afterMeals });
            }
        }
        return count;
    };

    const count_afternoon = () => {
        let count = [];
        const option = selectedOptions['Afternoon'];
        if (option.none != true) {
            if (option.beforeMeals) {
                count.push({ 3: option.quantities.beforeMeals });
            }
            if (option.afterMeals) {
                count.push({ 4: option.quantities.afterMeals });
            }
        }
        return count;
    };

    const count_evening = () => {
        let count = [];
        const option = selectedOptions['Evening'];
        if (option.none != true) {
            if (option.beforeMeals) {
                count.push({ 5: option.quantities.beforeMeals });
            }
            if (option.afterMeals) {
                count.push({ 6: option.quantities.afterMeals });
            }
        }
        return count;
    };

    const count_night = () => {
        let count = [];
        const option = selectedOptions['Night'];
        if (option.none != true) {
            if (option.beforeMeals) {
                count.push({ 7: option.quantities.beforeMeals });
            }
            if (option.afterMeals) {
                count.push({ 8: option.quantities.afterMeals });
            }
        }
        return count;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedMedicine) {
            toast.error('Please select medicine');
            return;
        }
        let time = checkTime();
        if (time == 0) {
            toast.error('Please Configure Time');
            return;
        }
        let slots = findSlot();
        let morning = count_morning();
        let afternoon = count_afternoon();
        let evening = count_evening();
        let night = count_night();

        const json = {
            patient_id: data.id,
            medicine_id: selectedMedicine.id,
            slot_ids: slots,
            days: selectedDays,
            count_morning: morning,
            count_afternoon: afternoon,
            count_evening: evening,
            count_night: night
        };
        setLoading(true);
        scheduleDoseByAdmin(json)
            .then((response) => {
                if (response?.data?.code == 200) {
                    toast.success('Medicine successfully scheduled', {
                        closeOnClick: true,
                        hideProgressBar: false
                    });
                    handleCancel();
                }
            })
            .catch((err) => {
                console.log(err.response?.data?.errorMessage);
                toast.error(err.response?.data?.errorMessage, {
                    closeOnClick: true,
                    hideProgressBar: false
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        history.push(slugs.patient);
    };

    return (
        <div className='row'>
            {loading && <Loader />}
            <div className='card'>
                <h3 className='d-flex justify-content-center align-items-center mt-3 mb-2'>
                    Schedule Medicine for{' '}
                    <span className='ms-2 text-success'>
                        {data.firstName} {data.lastName}
                    </span>
                </h3>
                <div className='d-flex justify-content-center align-items-center container-xl'>
                    <form className='p-5' onSubmit={handleSubmit}>
                        <div className='form-group mb-3'>
                            <label className='fw-bold'>Medicine Name</label>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Search Medicine'
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            {query && medicineData.length > 0 && (
                                <ul className='list-group'>
                                    {medicineData.map((medicine) => (
                                        <li
                                            key={medicine.id}
                                            className='list-group-item'
                                            onClick={() => handleMedicineSelect(medicine)}
                                        >
                                            {medicine.name} - {medicine.companyName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {selectedMedicine && (
                                <div className='mt-3'>
                                    <h6>
                                        Selected Medicine:{' '}
                                        <span className='medicine-name label label-lg label-light-warning label-inline'>
                                            {selectedMedicine.name}{' '}
                                        </span>
                                    </h6>
                                </div>
                            )}
                        </div>

                        <div className='form-group mb-3'>
                            <DoseTimings />
                        </div>

                        <div className='form-group mb-3 items-center'>
                            <>
                                <label className='fw-bold'>Select Days</label>
                                {renderDayCheckboxes()}
                            </>
                        </div>

                        <button type='submit' className='btn btn-primary mt-4'>
                            Schedule
                        </button>
                        <button
                            type='button'
                            className='btn btn-secondary ms-2 mt-4'
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </form>
                </div>
            </div>
            {/* timings */}
            <div className='card align-items-center'>
                <h3 className='d-flex justify-content-center align-items-center mt-3 mb-2'>
                    Dose Timings of{' '}
                    <span className='ms-2 text-success'>
                        {data.firstName} {data.lastName}
                    </span>
                </h3>
                <div className='mx-auto mt-4'>{renderTimings(timingss)}</div>
                <button onClick={handleTimings} className='btn btn-primary m-5 w-15'>
                    Save Timings
                </button>
            </div>
        </div>
    );
}
