import { axios } from './axios';

export async function getStates() {
    return await axios.get(`/states`);
}

export async function changePassword(data) {
    return await axios.post(`/user/changePassword`, data);
}

export async function updateProfile(data) {
    return await axios.post(`/user/me`, data);
}

export async function getProfileDetails() {
    return await axios.get(`/user/me`);
}

export async function sendSupportMail(data) {
    return await axios.post(`/support`, data);
}

export async function getMedicineAdherenceDetails(id) {
    return await axios.get(`common/medicine/${id}/adherence`);
}
