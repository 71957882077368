import { axios } from "../axios";


export async function getAllDevice(params) {
    return await axios.get("/admin/devices", {
        params
    });

}

export async function getAllMappedUserDevices(params) {
    return await axios.get("/admin/complete-mappings", {
        params
    });

}

export async function bulkAddDevice(formData) {
    return await axios.post('/admin/bulk-device', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function getAllMappedCompanyDevices(params) {
    return await axios.get("/admin/mappings", {
        params
    });

}

export async function editDevice(id, data) {
    return await axios.put(`/admin/device/${id}`, data);

}

export async function blockDeviceMappingStatus(id) {
    return await axios.put(`/admin/device-mapping/${id}/status`);

}

export async function mapDevice(data) {
    return await axios.post(`/admin/mappings`, data);

}

export async function addDeviceInfo(data) {
    return await axios.post(`/admin/device`, data);

}