import { axios } from "../axios";


export async function getCompanyAdherence(params) {
    return await axios.get("/company/adherence", {
        params
    });
}

export async function getCompanyMedicineAdherence(params) {
    return await axios.get("/company/medicine-adherence", {
        params
    });
}