import { axios } from "../axios";


export async function getAdminAdherence(params) {
    return await axios.get("/admin/adherence", {
        params
    });
}

export async function getAdminMedicineAdherence(params) {
    return await axios.get("/common/medicine-adherence", {
        params
    });
}