import DropdownComponent from 'components/dropdown';
import { userContext } from 'context/Provider';
import { SidebarContext } from 'hooks/useSidebar';
import { string } from 'prop-types';
import React, { useContext, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { default as slugs, default as SLUGS } from 'resources/slugs';
import { Row } from 'simple-flexbox';

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    container: {
        height: 40
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 14,
            marginRight: 0
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        }
    }
}));

function HeaderComponent() {
    const { push } = useHistory();
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [firstName, setFirstName] = useState(localStorage.getItem('firstName'));
    const [lastName, setLastName] = useState(localStorage.getItem('lastName'));
    const { user, setUser } = useContext(userContext);
    console.log('🚀 ~ file: HeaderComponent.js ~ line 71 ~ HeaderComponent ~ user', user);

    window.addEventListener('storage', () => {
        console.log('change to local storage!');
        setFirstName(localStorage.getItem('firstName'));
        setLastName(localStorage.getItem('lastName'));
    });

    async function logout() {
        localStorage.clear();
        setUser(null);
        push(SLUGS.login);
    }

    let title;
    switch (true) {
        case currentItem === SLUGS.dashboard:
            title = 'Dashboard';
            break;
        case [SLUGS.device, SLUGS.mappedCompany, SLUGS.mappedUser].includes(currentItem):
            title = 'Device';
            break;
        case currentItem === SLUGS.patient:
            title = 'Patients';
            break;
        case currentItem === SLUGS.viewPatient:
            title = 'Patient Details';
            break;
        case [SLUGS.caretaker, SLUGS.caretakerMapping].includes(currentItem):
            title = 'Caregiver';
            break;
        case currentItem == slugs.viewCareTaker:
            title = 'Caregiver Details';
            break;
        case currentItem === SLUGS.company:
            title = 'Company';
            break;
        case currentItem === SLUGS.getCompanyUser:
            title = 'Company Users';
            break;
        case currentItem === SLUGS.viewCompanyUser:
            title = 'Company User Details';
            break;
        case currentItem === SLUGS.doctor:
            title = 'Doctor';
            break;
        case currentItem === SLUGS.medicine:
            title = 'Medicine';
            break;
        case currentItem.includes(SLUGS.viewMedicine):
            title = 'Medicine Details';
            break;
        case currentItem === SLUGS.slot:
            title = 'Slot';
            break;
        case currentItem.includes(SLUGS.viewSlot):
            title = 'Slot Details';
            break;
        case currentItem === SLUGS.contact:
            title = 'Support';
            break;
        case currentItem === SLUGS.settings:
            title = 'Settings';
            break;
        case currentItem === SLUGS.feeds:
            title = 'Feeds';
            break;
        case currentItem === SLUGS.viewFeed:
            title = 'Feed Details';
            break;
        case currentItem === SLUGS.viewAdherence:
            title = 'Adherence Snapshot';
            break;
        case currentItem === SLUGS.viewMedicineAdherence:
            title = 'Medicine Adherence';
            break;
        case currentItem.includes(SLUGS.viewPatientDoctor):
            title = 'Patients enrolled under doctor';
            break;
        // company
        case currentItem === SLUGS.viewCompanyAdherence:
            title = 'Adherence Snapshot';
            break;
        case currentItem === SLUGS.viewCompMedicineAdherence:
            title = 'Medicine Adherence';
            break;
        case currentItem === SLUGS.compPatient:
            title = 'Patients';
            break;
        case currentItem === SLUGS.compDoctor:
            title = 'Doctor Details';
            break;
        case currentItem === SLUGS.compUsers:
            title = 'Company Users';
            break;
        case currentItem === SLUGS.compDashboard:
            title = 'Dashboard';
            break;
        case currentItem === SLUGS.compViewPatient:
            title = 'Patient Details';
            break;
        // Doctor
        case currentItem === SLUGS.doctorDashboard:
            title = 'Dashboard';
            break;
        case currentItem === SLUGS.viewDoctorAdherence:
            title = 'Adherence Snapshot';
            break;
        case currentItem === SLUGS.viewDocMedicineAdherence:
            title = 'Medicine Adherence';
            break;
        case currentItem === SLUGS.docPatient:
            title = 'Patients';
            break;
        case currentItem === SLUGS.docViewPatient:
            title = 'Patient Details';
            break;
        case currentItem === SLUGS.docCaregiver:
            title = 'Caregiver data';
            break;
        case currentItem === SLUGS.viewDocCaregiver:
            title = 'Caregiver details';
            break;
        case currentItem === SLUGS.docCareTakerMapping:
            title = 'Caregiver associated with patients';
            break;

        // common
        case currentItem === SLUGS.updateProfile:
            title = 'Update Profile';
            break;
        case currentItem === SLUGS.changePassword:
            title = 'Change Password';
            break;
        case currentItem.includes(SLUGS.viewMedicineAdherenceDetails):
            title = 'Medicine Adherence: Snapshot';
            break;

        default:
            title = '';
    }

    function onSettingsClick() {
        push(SLUGS.settings);
    }
    function updateProfile() {
        push(SLUGS.updateProfile);
    }
    function changePassword() {
        push(SLUGS.changePassword);
    }

    return (
        <Row className={classes.container} vertical='center' horizontal='space-between'>
            <span className={classes.title}>{title}</span>
            <Row vertical='center'>
                {/* <div className={classes.iconStyles}>
                    <IconSearch />
                </div>
                <div className={classes.iconStyles}>
                    <DropdownComponent
                        label={<IconBell />}
                        options={[
                            {
                                label: 'Notification #1',
                                onClick: () => console.log('Notification #1')
                            },
                            {
                                label: 'Notification #2',
                                onClick: () => console.log('Notification #2')
                            },
                            {
                                label: 'Notification #3',
                                onClick: () => console.log('Notification #3')
                            },
                            {
                                label: 'Notification #4',
                                onClick: () => console.log('Notification #4')
                            }
                        ]}
                        position={{
                            top: 42,
                            right: -14
                        }}
                    />
                </div> */}
                {/* <div className={classes.separator}></div> */}
                <DropdownComponent
                    label={
                        <>
                            <span className={classes.name}>{firstName + ' ' + lastName}</span>
                            <img
                                src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y'
                                alt='avatar'
                                className={classes.avatar}
                            />
                        </>
                    }
                    options={[
                        // {
                        //     label: 'Settings',
                        //     onClick: onSettingsClick
                        // },
                        {
                            label: 'Update Profile',
                            onClick: updateProfile
                        },
                        {
                            label: 'Change Password',
                            onClick: changePassword
                        },
                        {
                            label: 'Logout',
                            onClick: logout
                        }
                    ]}
                    position={{
                        top: 52,
                        right: -6
                    }}
                />
            </Row>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
