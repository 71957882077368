import React from 'react';
import { Link } from 'react-router-dom'
import { FaEye } from "react-icons/fa"

export default function ViewBtn({ path, data }) {
    return <div className='d-flex justify-content-center align-items-center'>
        <Link to={{
            pathname: `${path}`,
            state: data
        }}
            style={{ padding: "6px" }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button"
            title="View details"

        >
            <span className="svg-icon menu-icon text-light">
                <FaEye style={{ color: 'black' }} />
            </span>

        </Link>
    </div>
}
