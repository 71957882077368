import { axios } from "../axios";


export async function getAllUsers(params) {
    return await axios.get("/doctor/users", {
        params
    });

}

export async function getAllCaretakerUserMapping(params) {
    return await axios.get("/doctor/caretaker-mapping", {
        params
    });

}
