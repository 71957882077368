import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getUserDoses, getUserMedicineAdherence } from 'resources/serverReq/admin/user';
import "./PatientView.css"
import PatientRow from './PatientRow';
export default function PatientView(props) {
    const data = props.location.state;
    const [medicineDetails, setMedicineDetails] = useState([]);
    const [medicineAdherenceDetails, setMedicineAdherenceDetails] = useState([]);

    useEffect(() => {
        getUserDoses(data.id)
            .then((response) => {
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.data;
                    console.log('🚀 ~ file: PatientView.js ~ line 15 ~ getUserDoses ~ res', res);
                    setMedicineDetails(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        getUserMedicineAdherence(data.id)
            .then((response) => {
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.medicines;
                    console.log('🚀 ~ file: PatientView.js ~ line 15 ~ getUserMedicineAdherence ~ res', res);
                    setMedicineAdherenceDetails(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    console.log('🚀 ~ file: PatientView.js ~ line 7 ~ PatientView ~ data', data);
    return (
        <>
            <div className='main'>
                <div className='d-flex'>
                <div className='row'>
                    <div className='card'>
                        <div className='card-body'>
                            <i className='fa fa-pen fa-xs edit'></i>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Name:</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.firstName + ' ' + data?.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>DOB:</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.dob}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Email:</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Phone</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Gender</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.gender}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>City</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.city}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>State</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data?.state}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Care Giver Code</b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <span className='label label-lg label-light-warning label-inline'>
                                                {data?.reference_code}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Status</b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {data?.status ? (
                                                <span className='label label-lg label-light-success label-inline'>
                                                    Active
                                                </span>
                                            ) : (
                                                <span className='label label-lg label-light-danger label-inline'>
                                                    Inactive
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Created On</b>
                                        </td>
                                        <td>:</td>
                                        <td>{moment(data?.createdAt).format('DD-MMM-YY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {data?.deviceMappings?.length > 0 && (
                    <div className='row'>
                        <div className='card'>
                            <div className='card-body'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Device #</th>
                                            <th></th>
                                            <th></th>
                                            <th>Firmware Version</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.deviceMappings.map((d) => (
                                            <tr>
                                                <td>{d.device.name}</td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {d.device?.firmwareVersion == null ||
                                                    d?.device?.firmwareVersion.trim() == ''
                                                        ? 'N/A'
                                                        : d.device.firmwareVersion.trim()}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
                 {data?.deviceMappings?.length > 0 && (
                    <div className='row'>
                        <div className='card '>
                            <div className='card-body'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Device Serial</th>
                                            <th>Device Name</th>
                                            <th>Company Name</th>
                                            <th>Doctor Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.deviceMappings.map((d) => (
                                            <tr>
                                                <td>{d.device.name}</td>
                                                <td>{d.device.serialNumber}</td>
                                                <td>{d.company.name}</td>
                                                <td>
                                                    {d.doctor.firstName} {d.doctor.lastName}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
                </div>

                { <PatientRow medicineAdherenceDetails={medicineAdherenceDetails} /> }

                {/* {medicineDetails.length > 0 && (
                    <div className='row'>
                        <div className='card col-md-6 offset-md-3'>
                            <div className='card-body'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th></th>
                                            <th>Company Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {medicineDetails.map((d) => (
                                            <tr>
                                                <td>{d.medicineDetails.name}</td>
                                                <td></td>
                                                <td>{d.medicineDetails.companyName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )} */}

               
            </div>
        </>
    );
}
