import React from 'react'

export function TableColumn(props) {
    const { columns } = props
    return (
        <>
            <thead>
                <tr>
                    {
                        columns?.map((el, i) => (
                            <th key={i}>{el}</th>

                        ))
                    }
                </tr>
            </thead>


        </>
    )
}
