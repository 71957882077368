import { IconDocument } from 'assets/icons'
import { TableColumn, TableHeader, TableLastChild } from 'components/table'
import Loader from 'components/table/Loader'
import Pagination from 'components/table/Pagination'
import moment from "moment"
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllCompany } from 'resources/serverReq/admin/company'
import { blockDeviceMappingStatus, getAllDevice } from 'resources/serverReq/admin/device'
import SLUGS from 'resources/slugs'
import "../../common/common.css"
import '../../table/table.css'
import SelectInput from '../SelectInput'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function Device() {


    const [deviceData, setDeviceData] = useState([])
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false)
    const [status, setStatus] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [companyId, setCompanyId] = useState(null);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        getAllCompany()
            .then(({ data }) => {
                const companies = data.data.companies.rows;
                let options = [];
                companies.map((val) => {
                    options.push({
                        value: val.id,
                        label: val.name
                    });
                });
                setCompanyOptions(options);
            })
            .catch((err) => {
                console.log("🚀 ~ file: Adherence.js ~ line 96 ~ useEffect ~ err", err)
            });

    }, [])

    const history = useHistory();

    const handleBulkAddClick = () => {
        history.push(SLUGS.addBulkDevice);
    };


    useEffect(() => {
        const params = {
            page: page,
            search: query,
            status: status,
            company_id: companyId

        };
        getAllDevice(params).then((response) => {
            setIsLoading(true)
            setDataNotAvailable(false)
            if (response?.data?.code == 200) {
                const res = response?.data?.data.devices
                setDeviceData(res.rows)
                setPageData({ count: res.count, currentPage: res.currentPage, totalPage: res.totalPage })
                if (res.count == 0) setDataNotAvailable(true)

            }
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            setDataNotAvailable(false)
        })

    }, [query, status, page, companyId])

    async function blockDeviceMapping(id) {
        await blockDeviceMappingStatus(id).then((res) => {

            if (res.data.code === 200) {
                const deviceLists = deviceData.slice();
                for (const device of deviceLists) {
                    if (device.device_mapping.id === id) {
                        device.device_mapping.status = !device.device_mapping.status;
                        break;
                    }
                }
                setDeviceData(deviceLists);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    return (
        <>
            {
                !isLoading ?
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className=''>
                                <button className="btn btn-primary mx-2" onClick={handleBulkAddClick}>
                                  Add Multiple Devices
                                </button>
                             <TableHeader btnTitle={"+ Add Device "} route={SLUGS.addDevice} setQuery={setQuery} query={query} />
                            <SelectInput status={status} setStatus={setStatus} companyOptions={companyOptions} companyId={companyId} setCompanyId={setCompanyId} />
                            </div>
                            <table className="table table-borderless text-center">
                                <TableColumn columns={["Name", "Description", "Serial Number", "Company", "Status", "Firmware", "Created On", "Actions"]} />

                                <tbody>
                                    {
                                        dataNotAvailable ? <tr><td></td><td></td><td></td><td></td><td>No Record Found</td><td></td></tr> :
                                            deviceData.map((el) => (
                                                <tr key={`key-${el.id}`}>
                                                    <td>
                                                        <div className="d-flex flex-row bd-highlight p-2 ">
                                                            {/* <div className="d-flex align-items-start rounded-top">
                                                                <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                                    <img className="image-position symbol-round"
                                                                        src={
                                                                            "/assets/img/300_21.jpg"
                                                                        }
                                                                        alt="pic"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <span className='p-2'>
                                                                {el.name}

                                                            </span>
                                                        </div>

                                                    </td>

                                                    <td className='description-btn'>{el.description}</td>
                                                    <td>{el.serialNumber}</td>
                                                    <td>
                                                        {/* {
                                                            el.isSold == false ?
                                                                <span className='label label-lg label-light-success label-inline'>Not Sold</span> :
                                                                <span className='label label-lg label-light-danger label-inline'>Sold Out</span>
                                                        } */}
                                                        {el?.device_mapping?.company?.name}
                                                    </td>
                                                    <td>
                                                        {
                                                            el?.device_mapping?.status && el?.device_mapping?.status == true ?
                                                                <span className='label label-lg label-light-success label-inline'>Active</span> :
                                                                <span className='label label-lg label-light-danger label-inline'>Inactive</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {el.firmwareVersion}
                                                    </td>
                                                    <td>{moment(el.createdAt).format('DD-MMM-YY')}</td>
                                                    <td className='d-flex'>
                                                        {el.device_mapping == null || true ?
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <Link to={{
                                                                    pathname: `${SLUGS.mapDevice}`,
                                                                    state: el
                                                                }}
                                                                    style={{ backgroundColor: "#006FD8", padding: "6px" }}
                                                                    className="btn btn-xs btn-sm text-light sm-btn svg-icon-button"
                                                                    title="Invite User text-info"

                                                                >
                                                                    <span className="svg-icon menu-icon mr-2 text-light">
                                                                        <IconDocument fill={"white"} /> Map Device
                                                                    </span>

                                                                </Link>
                                                            </div> : <></>
                                                        }
                                                        <TableLastChild editRoute={SLUGS.editDevice} editData={el} block={blockDeviceMapping} blockId={el.device_mapping?.id} BlockCondition={el.device_mapping?.status} />
                                                    </td>
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                            <Pagination pageData={pageData} setPage={setPage} />

                        </div>
                    </div>
                    : <Loader />
            }
        </>
    )
}
