import { axios } from "../axios";


export async function getAllMedicine(params) {
    return await axios.get("/admin/medicines", {
        params
    });

}

export async function viewMedicineDetails(id) {
    return await axios.get(`/admin/medicine/${id}`);

}

export async function editMedicine(id, data) {
    return await axios.put(`/admin/medicine/${id}`, data);

}

export async function addMedicine(data) {
    return await axios.post(`/admin/medicine`, data);

}

export async function blockMedicine(id) {
    return await axios.put(`/admin/medicine/${id}/status`);

}

export async function uploadMedicineCsv(data) {
    return await axios.post(`/admin/medicine-upload`, data);
}
