import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { viewMedicineDetails } from "resources/serverReq/admin/medicine";

export default function ViewMedicine(props) {
    const path = props.location.pathname.split("/");
    const id = path[path.length - 1];
    const [medicineData, setMedicineData] = useState({})
    useEffect(() => {

        viewMedicineDetails(id).then((res) => {
            setMedicineData(res?.data?.data?.medicine)

        }).catch((err) => {
            console.log(err)
        })

    }, [])


    return (
        <div className="main">
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <div className="card-body">
                        <i className="fa fa-pen fa-xs edit"></i>
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>Name:</b></td>
                                    <td>:</td>
                                    <td>{medicineData.name}</td>
                                </tr>
                                <tr>
                                    <td><b>Company Name</b></td>
                                    <td>:</td>
                                    <td>{medicineData.companyName}</td>
                                </tr>
                                <tr>
                                    <td><b>Display Name:</b></td>
                                    <td>:</td>
                                    <td>{medicineData.status ? <span className='label label-lg label-light-success label-inline'>Active</span> :
                                        <span className='label label-lg label-light-danger label-inline'>Inactive</span>}</td>
                                </tr>
                                <tr>
                                    <td><b>Created On</b></td>
                                    <td>:</td>
                                    <td>{moment(medicineData.createdAt).format('DD-MMM-YY')}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
