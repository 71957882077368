import moment from 'moment';
import React from 'react';

export default function ViewFirmware(props) {
    const data = props.location.state;
    console.log('🚀 ~ file: ViewFeed.js ~ line 7 ~ ViewFeed ~ data', data);
    return (
        <>
            <div className='main'>
                <div className='row'>
                    <div className='card col-md-6 offset-md-3'>
                        <div className='card-body'>
                            <i className='fa fa-pen fa-xs edit'></i>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Version:</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data.version}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>File Url:</b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <a href={data.fileUrl} target='_blank'>Download</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Status</b>
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {data.status ? (
                                                <span className='label label-lg label-light-success label-inline'>
                                                    Active
                                                </span>
                                            ) : (
                                                <span className='label label-lg label-light-danger label-inline'>
                                                    Inactive
                                                </span>
                                            )}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Whats New</b>
                                        </td>
                                        <td>:</td>
                                        <td>{data.remark}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Created On</b>
                                        </td>
                                        <td>:</td>
                                        <td>{moment(data.createdAt).format('DD-MMM-YY')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
