import moment from 'moment';
import React from 'react';

export default function ViewFeed(props) {
    console.log("🚀 ~ file: ViewFeed.js ~ line 4 ~ ViewFeed ~ props", props)

    const data = props.location.state
    console.log("🚀 ~ file: ViewFeed.js ~ line 7 ~ ViewFeed ~ data", data)
    return <>
        <div className="main">
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <div className="card-body">
                        <i className="fa fa-pen fa-xs edit"></i>
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>Title:</b></td>
                                    <td>:</td>
                                    <td>{data.title}</td>
                                </tr>
                                <tr>
                                    <td><b>Description:</b></td>
                                    <td>:</td>
                                    <td>{data.description}</td>
                                </tr>
                                <tr>
                                    <td><b>Short Description:</b></td>
                                    <td>:</td>
                                    <td>{data.shortDescription}</td>
                                </tr>
                                <tr>
                                    <td><b>Status</b></td>
                                    <td>:</td>
                                    <td>{data.status ? <span className='label label-lg label-light-success label-inline'>Active</span> :
                                        <span className='label label-lg label-light-danger label-inline'>Inactive</span>}</td>
                                </tr>
                                <tr>
                                    <td><b>Created On</b></td>
                                    <td>:</td>
                                    <td>{moment(data.createdAt).format('DD-MMM-YY')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div className='card col-md-6 offset-md-3 mt-4 p-3'>
            <h5>Image Details</h5>
            {
                data.type == "image" ?
                    <img alt='feed-img' src={`${data.url}`} style={{ width: '200px' }} /> :
                    <a href={`${data.url}`} target="_blank">{data.url}</a>
            }
        </div>

    </>;
}
