import { FormBtns } from "components/table";
import { useFormik } from "formik";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { sendSupportMail } from "resources/serverReq/common";
import slugs from 'resources/slugs';
import * as Yup from "yup";
import "../common/common.css";


export default function Contact(props) {
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        message: "",

    };
    const [loading, setLoading] = useState(false);
    const contactUsSchema = Yup.object().shape({

        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),

        message: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(150, "Maximum 150 symbols")
            .required("Required"),
        phone: Yup.number()
            .min(10, "Minimum 10 symbols")
            .required("Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: contactUsSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            sendSupportMail(values).then((res) => {

                if (res?.data?.code === 200) {
                    toast.success('We will contact you shortly!')
                    props.history.push(slugs.dashboard)

                }
                disableLoading();
            })
                .catch((err) => {
                    console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    // toast.error(errorMessage, {
                    //     closeOnClick: true,
                    //     hideProgressBar: false,
                    // })
                });
        },
    });

    return (
        <div className='container-xl row'>

            <div className="justify-content-center align-items-center col-sm-6">
                <div className='support-add mb-5 text-center rounded'>
                    <span>Email ID: contact@dosetap.com</span><br />

                    <span>Contact No: +91- 74838 39811</span>


                </div>
                <form
                    onSubmit={formik.handleSubmit}

                >

                    <div className="form-group form-input-2 mb-3">
                        <label>Name</label>
                        <input
                            placeholder="Name"
                            type="name"
                            className={`form-control form-control-solid h-45px ${getInputClasses(
                                "name"
                            )}`}
                            name="name"
                            {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.name}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group form-input-2 mb-3">
                        <label>Email</label>
                        <input
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-45px ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>

                    <div className="form-group form-input-2 mb-3">
                        <label>Phone</label>
                        <input
                            placeholder="Phone"
                            type="text" pattern="\d*" maxLength={10}
                            className={`form-control form-control-solid h-45px ${getInputClasses(
                                "phone"
                            )}`}
                            name="phone"
                            {...formik.getFieldProps("phone")}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.phone}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group form-input-2 mb-3">
                        <label>Message</label>
                        <textarea
                            placeholder="text..."
                            type="message"
                            className={`form-control form-control-solid ${getInputClasses(
                                "message"
                            )}`}
                            rows="3"
                            cols="3"
                            name="message"
                            {...formik.getFieldProps("message")}
                        />
                        {formik.touched.message && formik.errors.message ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.message}</div>
                            </div>
                        ) : null}
                    </div>

                    {/* <div className="form-group form-btns d-flex flex-wrap justify-content-center align-items-center" style={{ marginTop: "45px" }}>
                        <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn referral-button`}
                        >
                            <span className='px-3'>Submit</span>
                        </button>
                        <button
                            type="button"
                            disabled={formik.isSubmitting}
                            className={`btn back-button`}
                        >
                            <span className='px-3'>Cancel</span>
                        </button>
                    </div> */}
                    <FormBtns formik={formik.isSubmitting} cancelRoute={""} />
                </form>
            </div>
            <div className='col-sm-6'>
                <img src="assets/img/help.gif" alt="gif" />
            </div>
        </div>
    );
}

