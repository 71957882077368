import Axios from 'axios';
import SLUGS from '../slugs';

export const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 10000,
});

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    return config;
  },
  (err) => {
    console.log("Something went wrong")
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    const ifConnected = window.navigator.onLine;
    if (!ifConnected) {

      console.log("Your internet connection is not available")
    }
    if (error.response.data === "Invalid token") {
      localStorage.clear()
      window.location.href = SLUGS.login

    }

    const { message } = error;
    if (error.message) {
      console.log(message)
    } else {
      console.log("Something went wrong")

    }
    return Promise.reject(error);
  }
);

