import { TableColumn, TableHeader, TableLastChild } from 'components/table'
import Loader from 'components/table/Loader'
import Pagination from 'components/table/Pagination'
import moment from "moment"
import React, { useEffect, useState } from 'react'
import { getStates } from "resources/serverReq/common"
import SLUGS from 'resources/slugs'
import { getAllUsers } from "../../../resources/serverReq/doctor/user"
import SelectInput from '../../admin/SelectInput'
import "../../common/common.css"
import '../../table/table.css'

export default function DocCaregiver() {
    let [userData, setUserData] = useState([]);
    const [perPageRows, setPerPageRows] = useState(7);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [status, setStatus] = useState("")
    const [state, setState] = useState("")
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [stateOptions, setStateOptions] = useState([]);
    const params = {
        page: page,
        role: perPageRows,
        search: query,
        role: "caretaker",
        sort: 1,
        status: status,
        state: state

    };

    useEffect(() => {

        getAllUsers(params).then((response) => {
            setIsLoading(true)
            setDataNotAvailable(false)
            if (response?.data?.code == 200) {
                const res = response?.data?.data.users
                setPageData({ count: res.count, currentPage: res.currentPage, totalPage: res.totalPage })
                setUserData(res.rows)
                if (res.count == 0) setDataNotAvailable(true)

            }
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            setDataNotAvailable(true)
        })

        getStates().then(({ data }) => {
            const states = data.data.states;
            let options = []
            states.map(val => {
                options.push({
                    value: val.name,
                    label: val.name
                })
            });
            setStateOptions(options);
        }).catch(err => {
            console.log("🚀 ~ file: AddPatient.js ~ line 73 ~ useEffect ~ err", err)
        });

    }, [status, query, state, page])


    return (
        <>
            {

                !isLoading ?
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="table-title">

                                <TableHeader setQuery={setQuery} query={query} />
                                <SelectInput stateOptions={stateOptions} state={state} setState={setState} status={status} setStatus={setStatus} />

                            </div>
                            <table className="table table-borderless text-center">
                                <TableColumn columns={["Name", "Email", "Gender", "Mobile", "City", "State", "Status", "Created On", "Actions"]} />
                                <tbody>
                                    {dataNotAvailable ? <tr><td></td><td></td><td></td><td></td><td>No Record Found</td><td></td><td></td><td></td><td></td></tr> : userData.map((el) => (
                                        <tr key={el.id}>
                                            <td>
                                                <div className="d-flex flex-row bd-highlight p-2 ">
                                                    {/* <div className="d-flex align-items-start rounded-top">
                                                        <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                            <img className="image-position symbol-round"
                                                                src={
                                                                    "/assets/img/300_21.jpg"
                                                                }
                                                                alt="pic"
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <span className='p-2'>
                                                        {el.firstName + " " + el.lastName}

                                                    </span>
                                                </div>

                                            </td>

                                            <td>{el.email}</td>
                                            <td>{el.gender}</td>
                                            <td>{el.phone}</td>
                                            <td>{el.city}</td>
                                            <td>{el.state}</td>
                                            <td>
                                                {
                                                    el.status == true ?
                                                        <span className='label label-lg label-light-success label-inline'>Active</span> :
                                                        <span className='label label-lg label-light-danger label-inline'>Inactive</span>
                                                }
                                            </td>
                                            <td>{moment(el.createdAt).format('DD-MMM-YY')}</td>
                                            <td >
                                                <div className='d-flex'>
                                                    <TableLastChild editData={el} path={SLUGS.viewDocCaregiver} />

                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Pagination pageData={pageData} setPage={setPage} />

                        </div>
                    </div>
                    : <Loader />

            }
        </>
    )

}
