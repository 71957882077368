import { TableColumn } from 'components/table'
import Loader from 'components/table/Loader'
import Pagination from 'components/table/Pagination'
import moment from "moment"
import React, { useEffect, useState } from 'react'
import { getAllMappedCompanyDevices } from 'resources/serverReq/admin/device'
import "../../common/common.css"
import '../../table/table.css'

export default function AllMappedCompany() {
    const [userData, setUserData] = useState([])
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const params = {
            page: page,
            search: query
        };
        getAllMappedCompanyDevices(params).then((response) => {
            setIsLoading(true)
            setDataNotAvailable(false)
            if (response?.data?.code == 200) {
                const res = response?.data?.data.deviceMappings
                setUserData(res.rows)
                setPageData({ count: res.count, currentPage: res.currentPage, totalPage: res.totalPage })
                if (res.count == 0) setDataNotAvailable(true)
            }
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            setDataNotAvailable(false)
        })


    }, [query, page])

    async function blockCompany(id) {
        // await blockDeviceStatus(id).then((res) => {

        //     console.log("🚀 ~ file: Company.js ~ line 39 ~ awaitblockDeviceStatus ~ res.data", res.data)
        //     if (res.data.code === 200) {
        //         const userLists = userData.slice();
        //         for (const user of userLists) {
        //             if (user.id === id) {
        //                 user.status = res?.data?.data?.user?.status;

        //                 break;
        //             }
        //         }
        //         setUserData(userLists);
        //     }
        // }).catch(function (error) {
        //     console.log(error.response);
        // });
    }

    return (
        <>
            {
                !isLoading ?
                    <div className="table-responsive">
                        <div className="table-wrapper">
                            <div className="search-box justify-content-end">

                                <input
                                    onChange={(event) => setQuery(event.target.value)}
                                    type="text"
                                    value={query}
                                    className="form-control"
                                    placeholder="Search&hellip;"
                                    autoFocus
                                />

                            </div>

                            <table className="table table-borderless text-center">
                                <TableColumn columns={["Company Name", "Device No.", "created On"]} />

                                <tbody>
                                    {
                                        dataNotAvailable ? <tr><td></td><td>No Record Found</td><td></td><td></td><td></td><td></td></tr> :
                                            userData.map((el) => (
                                                <tr key={el.id}>
                                                    <td >


                                                        <div className="d-flex flex-row bd-highlight p-2 ">
                                                            {/* <div className="d-flex align-items-start rounded-top">
                                                                <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                                    <img className="image-position symbol-round"
                                                                        src={
                                                                            "/assets/img/300_21.jpg"
                                                                        }
                                                                        alt="pic"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            <span className='p-2'>
                                                                {el.company.name}

                                                            </span>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        {el.device.serialNumber}
                                                    </td>
                                                    <td>{moment(el.createdAt).format('DD-MMM-YY')}</td>
                                                    {/* <td>

                                    <div className='d-flex'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <Link to={{
                                                pathname: `${SLUGS.viewMappedUser}`,
                                                state: el
                                            }}
                                                style={{ backgroundColor: "#006FD8", padding: "6px" }}
                                                className="btn btn-xs btn-sm text-light sm-btn svg-icon-button"
                                                title="Invite User text-info"

                                            >
                                                <span className="svg-icon menu-icon mr-2 text-light">
                                                    <IconDocument /> View Company Users
                                                </span>

                                            </Link>
                                        </div>
                                        
                                    </div>
                                </td> */}
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                            <Pagination pageData={pageData} setPage={setPage} />

                        </div>
                    </div>
                    : <Loader />
            }
        </>
    )
}
