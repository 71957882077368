import { FormBtns } from "components/table";
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import { createFeed, uploadMedia } from "resources/serverReq/admin/feed";
import slugs from 'resources/slugs';
import * as Yup from "yup";
import "../../common/common.css";
import "./style.css";

export default function CreateFeed(props) {
    const [fileNames, setFileNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [media, setMedia] = useState(null);
    const [mediaType, setMediaType] = useState("")
    const handleDrop = acceptedFiles => {
        setFileNames(acceptedFiles.map(file => file.name));
        setMedia(acceptedFiles[0])
    }
    const initialValues = {
        title: "dummy image and videos",
        description: "xfbhjx jdzfxjsd fjsdfsjdzx  xczcc zxjxncjzx xczkjfzx cjzxcx c",
        shortDescription: "zxcjkxc zxjkcjz fixdfsdzx",
        status: true,
        type: mediaType
    }
    console.log("🚀 ~ file: CreateFeed.js ~ line 28 ~ CreateFeed ~ mediaType", mediaType)

    useEffect(() => {
        if (mediaType == "video") {
            setMedia(null)
            setFileNames([])
        }


    }, [mediaType])



    const companySchema = Yup.object().shape({



        title: Yup.string()
            .required("Required"),
        description: Yup.string()
            .min(10, "Minimum 10 symbols")
            .max(150, "Maximum 250 symbols")
            .required("Required"),
        shortDescription: Yup.string()
            .min(10, "Minimum 10 symbols")
            .max(150, "Maximum 50 symbols")
            .required("Required"),

    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const handleImageChange = (e) => {
        e.preventDefault()
        setMediaType("image")
    }
    const handleVideoChange = (e) => {
        e.preventDefault()
        setMediaType("video")
    }

    const formik = useFormik({
        initialValues,
        validationSchema: companySchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            console.log("🚀 ~ file: CreateFeed.js ~ line 66 ~ CreateFeed ~ values", values)
            enableLoading();


            const mediaFormData = new FormData();

            if (media) {
                mediaFormData.append("profile_picture", media, media.name);

                uploadMedia(mediaFormData).then((res) => {
                    if (res?.data?.code === 200) {
                        const paylod = {
                            title: values.title,
                            description: values.description,
                            shortDescription: values.shortDescription,
                            status: true,
                            type: mediaType,
                            url: res?.data?.data?.url

                        }
                        createFeed(paylod).then((res) => {

                            if (res?.data?.code === 200) {
                                toast.success('New Feed added in system')

                                props.history.push(slugs.feeds)
                            }
                            disableLoading();
                        })
                            .catch((err) => {
                                console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                                disableLoading();
                                setSubmitting(false);
                                const errorMessage = err.response.data.errorMessage;
                                toast.error(errorMessage, {
                                    closeOnClick: true,
                                    hideProgressBar: false,
                                })
                            });
                    }
                })
                    .catch((err) => {
                        console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                        disableLoading();
                        setSubmitting(false);
                        const errorMessage = err.response.data.errorMessage;
                        toast.error(errorMessage, {
                            closeOnClick: true,
                            hideProgressBar: false,
                        })
                    });
            } else {
                const paylod = {
                    title: values.title,
                    description: values.description,
                    shortDescription: values.shortDescription,
                    status: true,
                    type: mediaType,
                    url: values.url
                }
                createFeed(paylod).then((res) => {

                    if (res?.data?.code === 200) {
                        toast.success('New Feed added in system')
                        props.history.push(slugs.feeds)
                    }
                    disableLoading();
                })
                    .catch((err) => {
                        console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                        disableLoading();
                        setSubmitting(false);
                        const errorMessage = err.response.data.errorMessage;
                        toast.error(errorMessage, {
                            closeOnClick: true,
                            hideProgressBar: false,
                        })
                    });
            }

        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>

                    <h3 className='d-flex justify-content-center align-items-center form-title mt-5 mb-5'>Create Feed</h3>
                    <div className="container-xl">
                        <form
                            onSubmit={formik.handleSubmit}

                        >

                            <div className="form-group form-input-2 mb-3">
                                <label>Title</label>
                                <input
                                    placeholder="Title"
                                    type="title"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "title"
                                    )}`}
                                    name="title"
                                    {...formik.getFieldProps("title")}
                                />
                                {formik.touched.title && formik.errors.title ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.title}</div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="form-group form-input-2 mb-3">
                                <label>Short Description</label>
                                <textarea
                                    placeholder="Short Description..."
                                    rows={2}
                                    cols={2}
                                    type="shortDescription"
                                    className={`form-control form-control-solid ${getInputClasses(
                                        "shortDescription"
                                    )}`}
                                    name="shortDescription"
                                    {...formik.getFieldProps("shortDescription")}
                                />
                                {formik.touched.shortDescription && formik.errors.shortDescription ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.shortDescription}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group form-input-2 mb-3">
                                <label> Description</label>
                                <textarea
                                    placeholder="description..."
                                    rows={3}
                                    cols={3}
                                    type="description"
                                    className={`form-control form-control-solid ${getInputClasses(
                                        "description"
                                    )}`}
                                    name="description"
                                    {...formik.getFieldProps("description")}
                                />
                                {formik.touched.description && formik.errors.description ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.description}</div>
                                    </div>
                                ) : null}
                            </div>


                            <div className="form-check">

                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={handleImageChange} />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Image
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={handleVideoChange} />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Video
                                </label>
                            </div>








                            {/* <div className="form-group ">
                                <label >Type</label>
                                <select placeholder="Type"
                                    type="type"
                                    className={`select-input form-control-solid ${getInputClasses(
                                        "type"
                                    )} p-2`}
                                    name="type"
                                    value={mediaType}
                                    {...formik.getFieldProps("type")}
                                >
                                    <option >Select..</option>
                                    <option value="image">Image</option>
                                    <option value="video">Video</option>
                                </select>
                                {formik.touched.type && formik.errors.type ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.type}</div>
                                    </div>
                                ) : null}

                            </div> */}
                            {
                                mediaType == "image" ?


                                    <div className="mt-3">
                                        <Dropzone
                                            onDrop={handleDrop}
                                            accept="image/*"
                                            minSize={1024}
                                            maxSize={3072000}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag'n'drop images, or click to select files</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div>
                                            <strong>Files:</strong>
                                            <ul>
                                                {fileNames.map(fileName => (
                                                    <li key={fileName}>{fileName}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                            {
                                mediaType == "video" ?


                                    <div className="form-group form-input-2 mb-3 mt-3">
                                        <label>Url</label>
                                        <input
                                            placeholder="Video url"
                                            type="url"
                                            className={`form-control form-control-solid h-45px ${getInputClasses(
                                                "url"
                                            )}`}
                                            name="url"
                                            {...formik.getFieldProps("url")}
                                        />

                                    </div>
                                    :
                                    ""
                            }

                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.feeds} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

