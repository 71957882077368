import { TableColumn } from 'components/table';
import Loader from 'components/table/Loader';
import Pagination from 'components/table/Pagination';
import React, { useEffect, useState } from 'react';
import { getPatientsMedicine } from '../../../resources/serverReq/doctor/dose';
import '../../common/common.css';
import '../../table/table.css';
import PatientRow from 'components/admin/patient/PatientRow';

export default function DocPatientDose(props) {
    const [medicineData, setMedicineData] = useState([]);
    const [perPageRows, setPerPageRows] = useState(7);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const patientName = props.location.state.firstName + ' ' + props.location.state.lastName;

    const patient_id = props.location.state.id;

    useEffect(() => {
        getPatientsMedicine(patient_id)
            .then((response) => {
                setIsLoading(true);
                setDataNotAvailable(false);
                if (response?.data?.code == 200) {
                    const res = response?.data?.data?.medicines;
                    setMedicineData(res);
                    if (res.length == 0) setDataNotAvailable(true);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setDataNotAvailable(false);
            });
    }, []);

    return (
        <>
            {!isLoading ? (
                <> 
                {<PatientRow medicineAdherenceDetails={medicineData} />}
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}
