import Axios from 'axios';


export const axios = Axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    timeout: 10000,
});
axios.interceptors.request.use(
    config => {
        return config;
    },
    err => Promise.reject(err)
);

export async function login(userLogin) {
    return await axios.post("/login", userLogin);

}
export async function forgotPass(data) {
    return await axios.post("/forgot-password", data);
}

export async function verifyResetToken(token) {
    return await axios.get(`/reset/${token}`);
}

export async function resetPassword(token, data) {
    return await axios.post(`/reset/${token}`, data);
}