import {axios} from "../axios"


export async function getAllSlot(params) {
    return await axios.get("/admin/slots",{
        params
    });

}

export async function viewSlotDetails(id) {
    return await axios.get(`/admin/slot/${id}`);

}

export async function editSlot(id,data) {
    return await axios.put(`/admin/slot/${id}`,data);

}

export async function closeSlot(id) {
    return await axios.put(`/admin/device/${id}/status`);

}

export async function addSlotData(data) {
    return await axios.post(`/admin/slot`,data);

}