import React from 'react';


export default function SelectInput({ stateOptions = [], state, setState, setStatus, status, companyOptions = [], companyId, setCompanyId }) {


    return <>
        <div className='d-flex'>

            {stateOptions.length > 0 &&
                <div className="form-group w-15">
                    <label className='text-dark'>State</label>
                    <select placeholder="State"
                        type="state"
                        className={`select-input form-control-solid p-2`}
                        name="state"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                    >
                        <option value="">Select..</option>
                        {stateOptions.map((el) => (
                            <option key={el.value}>{el.value}</option>
                        ))}
                    </select>
                </div>
            }
            <div className="form-group">
                <label className='text-dark'>Status</label>
                <select className="select-input p-2" onChange={(e) => setStatus(e.target.value)} value={status}>
                    <option value="">Select</option>
                    <option value="1" >Active</option>
                    <option value="0">Inactive</option>
                </select>
            </div>

            {companyOptions.length > 0 &&
                <div className='form-group w-15'>
                    <label className='text-dark'>Company</label>
                    <select
                        placeholder='Company'
                        type='company'
                        className={`select-input form-control-solid p-2`}
                        name='company'
                        onChange={(event) => {
                            event.preventDefault();
                            setCompanyId(event.target.value);
                        }}
                        value={companyId}
                    >
                        <option value="">Select..</option>
                        {companyOptions.map((el) => (
                            <option key={el.value} value={el.value}>{el.label}</option>
                        ))}
                    </select>
                </div>
            }

        </div>
    </>;
}
