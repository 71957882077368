
import moment from 'moment';
import React from 'react';
export default function CareTakerView(props) {

    const data = props.location.state
    console.log("🚀 ~ file: PatientView.js ~ line 7 ~ PatientView ~ data", data)
    return <>

        <div className="main">
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <div className="card-body">
                        <i className="fa fa-pen fa-xs edit"></i>
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>Name:</b></td>
                                    <td>:</td>
                                    <td>{data.firstName + " " + data.lastName}</td>
                                </tr>
                                <tr>
                                    <td><b>DOB:</b></td>
                                    <td>:</td>
                                    <td>{data.dob}</td>
                                </tr>
                                <tr>
                                    <td><b>Email:</b></td>
                                    <td>:</td>
                                    <td>{data.email}</td>
                                </tr>
                                <tr>
                                    <td><b>Phone</b></td>
                                    <td>:</td>
                                    <td>{data.phone}</td>
                                </tr>
                                <tr>
                                    <td><b>Gender</b></td>
                                    <td>:</td>
                                    <td>{data.gender}</td>
                                </tr>
                                <tr>
                                    <td><b>City</b></td>
                                    <td>:</td>
                                    <td>{data.city}</td>
                                </tr>
                                <tr>
                                    <td><b>State</b></td>
                                    <td>:</td>
                                    <td>{data.state}</td>
                                </tr>
                                <tr>
                                    <td><b>Status</b></td>
                                    <td>:</td>
                                    <td>{data.status ? <span className='label label-lg label-light-success label-inline'>Active</span> :
                                        <span className='label label-lg label-light-danger label-inline'>Inactive</span>}</td>
                                </tr>
                                <tr>
                                    <td><b>Created On</b></td>
                                    <td>:</td>
                                    <td>{moment(data.createdAt).format('DD-MMM-YY')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    </>;
}
