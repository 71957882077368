import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getDoctorDashboard } from 'resources/serverReq/doctor/dashboard';
import { Column, Row } from 'simple-flexbox';
import MiniCardComponent from '../../cards/MiniCardComponent';
import TodayTrendsComponent from './TodayTrendsComponent';


const useStyles = createUseStyles({
    cardsContainer: {
        marginRight: -30,
        marginTop: -30
    },
    cardRow: {
        marginTop: 30,
        '@media (max-width: 768px)': {
            marginTop: 0
        }
    },
    miniCardContainer: {
        flexGrow: 1,
        marginRight: 30,
        '@media (max-width: 768px)': {
            marginTop: 30,
            maxWidth: 'none'
        }
    },
    todayTrends: {
        marginTop: 30
    },
    lastRow: {
        marginTop: 30
    },
    unresolvedTickets: {
        marginRight: 30,
        '@media (max-width: 1024px)': {
            marginRight: 0
        }
    },
    tasks: {
        marginTop: 0,
        '@media (max-width: 1024px)': {
            marginTop: 30
        }
    }
});

function DoctorDashboardComponent() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({
        'caretaker': 0,
        'company': 0,
        'company_user': 0,
        'user': 0,
        'doctor': 0,
        'avg_adherence_missed': 0,
        'avg_adherence_open': 0,
        'active_users': 0,
        'timeline_data': []
    })
    useEffect(() => {
        getDoctorDashboard().then((response) => {
            console.log("🚀 ~ file: DashboardComponent.js ~ line 61 ~ getCompanyDashboard ~ response", response)
            if (response?.data?.code == 200) {
                const res = response?.data
                console.log("🚀 ~ file: DashboardComponent.js ~ line 53 ~ getCompanyDashboard ~ res", res)
                setData(res.data)
                setIsLoading(false)
            }
        }).catch((err) => {
            console.log("🚀 ~ file: DashboardComponent.js ~ line 67 ~ getCompanyDashboard ~ err", err)
            setIsLoading(false)
        })
    }, [])

    return (
        !isLoading && <Column>

            <Row
                className={classes.cardsContainer}
                wrap
                flexGrow={1}
                horizontal='space-between'
                breakpoints={{ 768: 'column' }}
            >
                <Row
                    className={classes.cardRow}
                    wrap
                    flexGrow={1}
                    breakpoints={{ 384: 'column' }}
                >
                    <MiniCardComponent
                        className={classes.miniCardContainer}
                        title='Patients'
                        value={data.user}
                    />
                    <MiniCardComponent
                        className={classes.miniCardContainer}
                        title='Caregiver'
                        value={data.caretaker}
                    />
                </Row>
            </Row>
            <div className={classes.todayTrends}>
                <TodayTrendsComponent avg_adherence_open={data.avg_adherence_open} avg_adherence_missed={data.avg_adherence_missed} active_users={data.active_users} timeline_data={data.timeline_data} />
            </div>
        </Column>
    );
}

export default DoctorDashboardComponent;
