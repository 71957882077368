import Adherence from 'components/admin/adherence/Adherence';
import MedicineAdherence from 'components/admin/adherence/MedicineAdherence';
import MedicineAdherenceDetails from 'components/admin/adherence/MedicineAdherenceDetails';
import AddCareTaker from 'components/admin/caretaker/AddCareTaker';
import CareTaker from 'components/admin/caretaker/CareTaker';
import CareTakerUserMapping from 'components/admin/caretaker/CareTakerUserMapping';
import CareTakerView from 'components/admin/caretaker/CareTakerView';
import EditCareTaker from 'components/admin/caretaker/EditCareTaker';
import AddCompany from 'components/admin/company/AddCompany';
import Company from 'components/admin/company/Company';
import CompanyUsers from 'components/admin/company/companyUser/CompanyUsers';
import CompanyUser from 'components/admin/company/companyUser/CreateUser';
import EditCompanyUser from 'components/admin/company/companyUser/EditCompanyUser';
import ViewCompanyUser from 'components/admin/company/companyUser/ViewCompanyUser';
import EditCompany from 'components/admin/company/EditCompany';
import Contact from 'components/admin/Contact';
import AddDevice from 'components/admin/device/AddDevice';
import AllMappedCompany from 'components/admin/device/AllMappedCompany';
import AllMappedUsers from 'components/admin/device/AllMappedUsers';
import BulkAddDevice from 'components/admin/device/BulkAddDevice';
import Device from 'components/admin/device/Device';
import EditDevice from 'components/admin/device/EditDevice';
import MapDevice from 'components/admin/device/MapDevice';
import AddDoctor from 'components/admin/doctor/AddDoctor';
import Doctor from 'components/admin/doctor/Doctor';
import DoctorPatient from 'components/admin/doctor/DoctorPatient';
import EditDoctor from 'components/admin/doctor/EditDoctor';
import CreateFeed from 'components/admin/feed/CreateFeed';
import EditFeed from 'components/admin/feed/EditFeed';
import Feeds from 'components/admin/feed/Feeds';
import ViewFeed from 'components/admin/feed/ViewFeed';
import CreateFirmware from 'components/admin/firmware/CreateFirmware';
import EditFirmware from 'components/admin/firmware/EditFirmware';
import Firmwares from 'components/admin/firmware/Firmwares';
import ViewFirmware from 'components/admin/firmware/ViewFirmware';
import AddMedicine from 'components/admin/medicine/AddMedicine';
import EditMedicine from 'components/admin/medicine/EditMedicine';
import Medicine from 'components/admin/medicine/Medicine';
import ViewMedicine from 'components/admin/medicine/ViewMedicine';
import AddPatient from 'components/admin/patient/AddPatient';
import EditPatient from 'components/admin/patient/EditPatient';
import Patient from 'components/admin/patient/Patient';
import PatientView from 'components/admin/patient/PatientView';
import SchedulePatient from 'components/admin/patient/SchedulePatient';
import AddReferenceCode from 'components/admin/reference-code/AddReferenceCode';
import ReferenceCode from 'components/admin/reference-code/ReferenceCode';
import AddSlot from 'components/admin/slot/AddSlot';
import EditSlot from 'components/admin/slot/EditSlot';
import Slot from 'components/admin/slot/Slot';
import ViewSlot from 'components/admin/slot/ViewSlot';
import ChangePassword from 'components/auth/ChangePassword';
import CompanyAdherence from 'components/company/adherence/CompanyAdherence';
import MedicineCompAdherence from 'components/company/adherence/MedicineCompAdherence';
import MedicineCompAdherenceDetails from 'components/company/adherence/MedicineCompAdherenceDetails';
import CompanyDashboardComponent from 'components/company/dashboard';
import CompDoctor from 'components/company/doctors/CompDoctor';
import CompDoctorPatient from 'components/company/doctors/CompDoctorPatient';
import CompPatient from 'components/company/patient/CompPatient';
import CompUsers from 'components/company/user/CompUsers';
import DoctorAdherence from 'components/doctors/adherence/DoctorAdherence';
import MedicineDocAdherence from 'components/doctors/adherence/MedicineDocAdherence';
import MedicineDocAdherenceDetails from 'components/doctors/adherence/MedicineDocAdherenceDetails';
import DocCaregiver from 'components/doctors/caregiver/DocCaregiver';
import DocCareTakerUserMapping from 'components/doctors/caregiver/DocCareTakerUserMapping';
import ViewDocCaregiver from 'components/doctors/caregiver/ViewDocCaregiver';
import DoctorDashboardComponent from 'components/doctors/dashboard';
import DocPatient from 'components/doctors/patient/DocPatient';
import DocPatientDose from 'components/doctors/patient/DocPatientDose';
import LoadingComponent from 'components/loading';
import UpdateProfile from 'components/UpdateProfile';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';

const DashboardComponent = lazy(() => import('./dashboard'));

function PrivateRoutes() {
    const role = localStorage.getItem('role');

    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                {role == 'admin' ? (
                    <>
                        <Route exact path={SLUGS.dashboard} component={DashboardComponent} />
                        <Route exact path={SLUGS.patient} component={Patient} />
                        <Route exact path={SLUGS.editPatient} component={EditPatient} />
                        <Route exact path={SLUGS.schedulePatient} component={SchedulePatient} />
                        <Route exact path={SLUGS.addPatient} component={AddPatient} />
                        <Route exact path={SLUGS.company} component={Company} />
                        <Route exact path={SLUGS.medicine} component={Medicine} />
                        <Route exact path={SLUGS.slot} component={Slot} />
                        <Route exact path={SLUGS.device} component={Device} />
                        <Route exact path={SLUGS.doctor} component={Doctor} />
                        <Route exact path={SLUGS.contact} component={Contact} />
                        <Route exact path={SLUGS.addDevice} component={AddDevice} />
                        <Route exact path={SLUGS.addBulkDevice} component={BulkAddDevice} />
                        <Route exact path={SLUGS.editDevice} component={EditDevice} />
                        <Route exact path={SLUGS.editDoctor} component={EditDoctor} />
                        <Route exact path={SLUGS.addDoctor} component={AddDoctor} />
                        <Route
                            exact
                            path={SLUGS.viewPatientDoctor + '/:id'}
                            component={DoctorPatient}
                        />
                        <Route exact path={SLUGS.addMedicine} component={AddMedicine} />
                        <Route exact path={SLUGS.editMedicine} component={EditMedicine} />
                        <Route exact path={SLUGS.addSlot} component={AddSlot} />
                        <Route exact path={SLUGS.editSlot} component={EditSlot} />
                        <Route path={SLUGS.viewSlot} component={ViewSlot} />
                        <Route path={SLUGS.viewMedicine} component={ViewMedicine} />
                        <Route exact path={SLUGS.addCompany} component={AddCompany} />
                        <Route exact path={SLUGS.editCompany} component={EditCompany} />
                        <Route exact path={SLUGS.companyUser} component={CompanyUser} />
                        <Route path={SLUGS.mapDevice} component={MapDevice} />
                        <Route exact path={SLUGS.feeds} component={Feeds} />
                        <Route exact path={SLUGS.createFeed} component={CreateFeed} />
                        <Route exact path={SLUGS.viewFeed} component={ViewFeed} />
                        <Route exact path={SLUGS.editFeed} component={EditFeed} />
                        <Route exact path={SLUGS.viewPatient} component={PatientView} />
                        <Route exact path={SLUGS.getCompanyUser} component={CompanyUsers} />
                        <Route exact path={SLUGS.viewCompanyUser} component={ViewCompanyUser} />
                        <Route exact path={SLUGS.mappedUser} component={AllMappedUsers} />
                        <Route exact path={SLUGS.mappedCompany} component={AllMappedCompany} />
                        <Route exact path={SLUGS.caretaker} component={CareTaker} />
                        <Route exact path={SLUGS.editCareTaker} component={EditCareTaker} />
                        <Route exact path={SLUGS.addCareTaker} component={AddCareTaker} />
                        <Route exact path={SLUGS.viewCareTaker} component={CareTakerView} />
                        <Route
                            exact
                            path={SLUGS.caretakerMapping}
                            component={CareTakerUserMapping}
                        />
                        <Route exact path={SLUGS.editCompanyUser} component={EditCompanyUser} />
                        <Route exact path={SLUGS.viewAdherence} component={Adherence} />
                        <Route exact path={SLUGS.referenceCode} component={ReferenceCode} />
                        <Route exact path={SLUGS.addReferenceCode} component={AddReferenceCode} />
                        <Route
                            exact
                            path={SLUGS.viewMedicineAdherence}
                            component={MedicineAdherence}
                        />
                        <Route
                            exact
                            path={SLUGS.viewMedicineAdherenceDetails + '/:id'}
                            component={MedicineAdherenceDetails}
                        />
                        <Route exact path={SLUGS.firmware} component={Firmwares} />
                        <Route exact path={SLUGS.createFirmware} component={CreateFirmware} />
                        <Route exact path={SLUGS.viewFirmware} component={ViewFirmware} />
                        <Route exact path={SLUGS.editFirmware} component={EditFirmware} />
                        <Route path={SLUGS.updateProfile} component={UpdateProfile} />
                        <Route path={SLUGS.changePassword} component={ChangePassword} />

                        <Redirect to={SLUGS.dashboard} />
                    </>
                ) : role == 'company' ? (
                    <>
                        <Route
                            exact
                            path={SLUGS.compDashboard}
                            component={CompanyDashboardComponent}
                        />
                        <Route
                            exact
                            path={SLUGS.viewCompanyAdherence}
                            component={CompanyAdherence}
                        />
                        <Route
                            exact
                            path={SLUGS.viewCompMedicineAdherence}
                            component={MedicineCompAdherence}
                        />
                        <Route exact path={SLUGS.compPatient} component={CompPatient} />
                        <Route exact path={SLUGS.compDoctor} component={CompDoctor} />
                        <Route exact path={SLUGS.compUsers} component={CompUsers} />
                        <Route exact path={SLUGS.compViewPatient} component={PatientView} />
                        <Route exact path={SLUGS.viewCompanyUser} component={ViewCompanyUser} />
                        <Route
                            exact
                            path={SLUGS.viewPatientDoctor + '/:id'}
                            component={CompDoctorPatient}
                        />
                        <Route
                            exact
                            path={SLUGS.compViewMedicineAdherence}
                            component={MedicineCompAdherence}
                        />
                        <Route path={SLUGS.updateProfile} component={UpdateProfile} />
                        <Route path={SLUGS.changePassword} component={ChangePassword} />
                        <Route
                            exact
                            path={SLUGS.viewMedicineAdherenceDetails + '/:id'}
                            component={MedicineCompAdherenceDetails}
                        />
                        <Redirect to={SLUGS.compDashboard} />
                    </>
                ) : (
                    <>
                        <Route
                            exact
                            path={SLUGS.doctorDashboard}
                            component={DoctorDashboardComponent}
                        />
                        <Route exact path={SLUGS.viewDoctorAdherence} component={DoctorAdherence} />
                        <Route
                            exact
                            path={SLUGS.viewDocMedicineAdherence}
                            component={MedicineDocAdherence}
                        />
                        <Route exact path={SLUGS.docPatient} component={DocPatient} />
                        <Route exact path={SLUGS.docViewPatient} component={PatientView} />
                        <Route exact path={SLUGS.docCaregiver} component={DocCaregiver} />
                        <Route exact path={SLUGS.viewDocCaregiver} component={ViewDocCaregiver} />
                        <Route
                            exact
                            path={SLUGS.docCareTakerMapping}
                            component={DocCareTakerUserMapping}
                        />
                        <Route exact path={SLUGS.docPatientMedicine} component={DocPatientDose} />
                        <Route path={SLUGS.updateProfile} component={UpdateProfile} />
                        <Route path={SLUGS.changePassword} component={ChangePassword} />
                        <Route
                            exact
                            path={SLUGS.viewMedicineAdherenceDetails + '/:id'}
                            component={MedicineDocAdherenceDetails}
                        />
                        <Redirect to={SLUGS.doctorDashboard} />
                    </>
                )}
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
