import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSubscription,
    IconArrowUp,
    IconBell
} from 'assets/icons';
import { userContext } from 'context/Provider';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;
    const { user, setUser } = useContext(userContext);

    async function logout() {
        setUser(null);
        localStorage.clear();
        push(SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.dashboard}
                title='Dashboard'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.dashboard)}
            />
            <MenuItem
                id={SLUGS.viewAdherence}
                title='Adherence'
                items={[
                    SLUGS.viewAdherence,
                    SLUGS.viewMedicineAdherence,
                    SLUGS.viewMedicineAdherenceDetails
                ]}
                icon={IconArticles}
            >
                <MenuItem
                    id={SLUGS.viewAdherence}
                    title='Adherence'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.viewAdherence)}
                />
                <MenuItem
                    id={SLUGS.viewMedicineAdherence}
                    title='Medicine'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.viewMedicineAdherence)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.patient}
                title='Patients'
                icon={IconAgents}
                onClick={() => onClick(SLUGS.patient)}
            />
            <MenuItem
                id={SLUGS.caretaker}
                items={[SLUGS.caretaker, SLUGS.caretakerMapping]}
                title='Caregiver'
                icon={IconAgents}
            >
                <MenuItem
                    id={SLUGS.caretaker}
                    title='All Caregiver'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.caretaker)}
                />
                <MenuItem
                    id={SLUGS.caretakerMapping}
                    title='Caregiver Mapping'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.caretakerMapping)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.company}
                items={[SLUGS.company, SLUGS.companyUser]}
                title='Company'
                icon={IconIdeas}
            >
                <MenuItem
                    id={SLUGS.company}
                    title='All Company'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.company)}
                />
                <MenuItem
                    id={SLUGS.companyUser}
                    title='Company Users'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.companyUser)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.doctor}
                title='Doctors'
                icon={IconContacts}
                onClick={() => onClick(SLUGS.doctor)}
            />
            <MenuItem
                id={SLUGS.device}
                items={[SLUGS.device, SLUGS.mappedUser, SLUGS.mappedCompany]}
                title='Device'
                icon={IconIdeas}
            >
                <MenuItem
                    id={SLUGS.device}
                    title='All Device'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.device)}
                />
                <MenuItem
                    id={SLUGS.mappedUser}
                    title='Mapped User Device'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.mappedUser)}
                />
                <MenuItem
                    id={SLUGS.mappedCompany}
                    title='Mapped Company Device'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.mappedCompany)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.medicine}
                title='Medicine'
                icon={IconContacts}
                onClick={() => onClick(SLUGS.medicine)}
            />
            <MenuItem
                id={SLUGS.slot}
                title='Slot'
                icon={IconArticles}
                onClick={() => onClick(SLUGS.slot)}
            />

            <MenuItem
                id={SLUGS.referenceCode}
                title='Reference Code'
                icon={IconOverview}
                onClick={() => onClick(SLUGS.referenceCode)}
            />

            <MenuItem
                id={SLUGS.feeds}
                title='Feeds'
                icon={IconBell}
                onClick={() => onClick(SLUGS.feeds)}
            />
            <MenuItem
                id={SLUGS.firmware}
                title='Firmware'
                icon={IconArrowUp}
                onClick={() => onClick(SLUGS.firmware)}
            />
            <div className={classes.separator}></div>
            <MenuItem
                id={SLUGS.contact}
                title='Support'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.contact)}
            />

            <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={logout} />
        </Menu>
    );
}

export default SidebarComponent;
