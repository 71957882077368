import Loader from 'components/table/Loader';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getMedicineAdherenceDetails } from '../../../resources/serverReq/common';
import MiniCardComponent from '../../cards/MiniCardComponent';
import '../../common/common.css';
import '../../table/table.css';

const useStyles = createUseStyles({
    cardsContainer: {
        marginRight: -30,
        marginTop: -30
    },
    cardRow: {
        marginTop: 30,
        '@media (max-width: 768px)': {
            marginTop: 0
        }
    },
    miniCardContainer: {
        flexGrow: 1,
        marginRight: 20,
        marginTop: 10,
        '@media (max-width: 768px)': {
            marginTop: 30,
            maxWidth: 'none'
        }
    },
    todayTrends: {
        marginTop: 30
    },
    lastRow: {
        marginTop: 30
    },
    unresolvedTickets: {
        marginRight: 30,
        '@media (max-width: 1024px)': {
            marginRight: 0
        }
    },
    tasks: {
        marginTop: 0,
        '@media (max-width: 1024px)': {
            marginTop: 30
        }
    }
});
export default function MedicineCompAdherenceDetails(props) {
    const classes = useStyles();
    const path = props.location.pathname.split('/');
    const id = path[path.length - 1];
    const { title = 'Medicine' } = props.location.state || {};
    console.log(
        '🚀 ~ file: MedicineAdherenceDetails.js ~ line 53 ~ MedicineAdherenceDetails ~ title',
        title
    );
    const [isLoading, setIsLoading] = useState(true);
    const [adherence, setAdherence] = useState({});

    useEffect(() => {
        getMedicineAdherenceDetails(id)
            .then((response) => {
                setIsLoading(true);
                if (response?.data?.code == 200) {
                    const res = response?.data?.data;
                    setAdherence(res);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            {!isLoading ? (
                <div className='flex'>
                    <h4>{title}</h4>
                    <div className='row'>
                        <MiniCardComponent
                            className={classes.miniCardContainer}
                            title='Total Patient'
                            value={adherence.totalPatients}
                        />
                        <MiniCardComponent
                            className={classes.miniCardContainer}
                            title='Total Taken'
                            value={ adherence.totalOpen }
                        />
                        <MiniCardComponent
                            className={classes.miniCardContainer}
                            title='Total Taken %'
                            value={
                                adherence.totalOpen === 0
                                    ? 0
                                    : (
                                          (adherence.totalOpen /
                                              (adherence.totalOpen + adherence.totalMissed)) *
                                          100
                                      ).toFixed(2)
                            }
                        />
                        <MiniCardComponent
                            className={classes.miniCardContainer}
                            title='Total Missed'
                            value={ adherence.totalMissed }
                        />
                        <MiniCardComponent
                            className={classes.miniCardContainer}
                            title='Total Missed %'
                            value={
                                adherence.totalMissed === 0
                                    ? 0
                                    : (
                                          (adherence.totalMissed /
                                              (adherence.totalOpen + adherence.totalMissed)) *
                                          100
                                      ).toFixed(2)
                            }
                        />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
