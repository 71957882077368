import { axios } from "../axios";


export async function getAllUsers(params) {
    return await axios.get("/company/users", {
        params
    });

}

export async function getAllCompanyUsers(params) {
    return await axios.get(`company/company-users`, {
        params
    });

}

export async function getAllPatientsByDoctor(id, params) {
    return await axios.get(`company/doctor/${id}/patients`, {
        params
    });
}
