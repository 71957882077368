import React, { useEffect, useState } from 'react';
import { viewSlotDetails } from "resources/serverReq/admin/slot";

export default function ViewSlot(props) {
    const path = props.location.pathname.split("/");
    const id = path[path.length - 1];
    const [slotData, setSlotData] = useState({})
    useEffect(() => {

        viewSlotDetails(id).then((res) => {

            setSlotData(res?.data?.data?.slot)

        }).catch((err) => {
            console.log(err)
        })

    }, [])

    return (
        <div className="main">
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <div className="card-body">
                        <i className="fa fa-pen fa-xs edit"></i>
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>Slot Name:</b></td>
                                    <td>:</td>
                                    <td>{slotData.name}</td>
                                </tr>
                                <tr>
                                    <td><b>Type:</b></td>
                                    <td>:</td>
                                    <td>{slotData.type}</td>
                                </tr>
                                <tr>
                                    <td><b>Display Name:</b></td>
                                    <td>:</td>
                                    <td>{slotData.displayName}</td>
                                </tr>
                                <tr>
                                    <td><b>Display Type</b></td>
                                    <td>:</td>
                                    <td>{slotData.displayType}</td>
                                </tr>
                                <tr>
                                    <td><b>Start Time</b></td>
                                    <td>:</td>
                                    <td>{slotData.startTime}</td>
                                </tr>
                                <tr>
                                    <td><b>End Time</b></td>
                                    <td>:</td>
                                    <td>{slotData.endTime}</td>
                                </tr>
                                <tr>
                                    <td><b>Order</b></td>
                                    <td>:</td>
                                    <td>{slotData.order}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
