import { userContext } from 'context/Provider';
import useWindowSize from 'hooks/useWindowSize';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
function Routes() {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();
    const { user, setUser } = useContext(userContext)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const isAuthorized = localStorage.getItem("token")

    const isUserLoggedIn = user || isAuthorized != null ? user || isAuthorized : false;
    // return isUserLoggedIn ? <PrivateSection /> : <PrivateSection />;
    return isUserLoggedIn ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
