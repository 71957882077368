export default {
    // private admin
    dashboard: '/dashboard',
    overview: '/overview',
    overviewTwo: '/overview/two',
    overviewThree: '/overview/three',
    company: '/company',
    medicine: '/medicine',
    contact: '/contact',
    device: '/device',
    patient: '/patient',
    addPatient: '/add-patient',
    editPatient: '/edit-patient',
    schedulePatient: '/schedule-patient',
    ideas: '/ideas',
    ideasThree: '/ideas/three',
    contacts: '/contacts',
    agents: '/agents',
    articles: '/articles',
    settings: '/settings',
    subscription: '/subscription',
    addDevice: '/add-device',
    addBulkDevice: '/add-bulk-device',
    editDevice: '/edit-device',
    addMedicine: '/add-medicine',
    editMedicine: '/edit-medicine',
    slot: '/slot',
    addSlot: '/add-slot',
    editSlot: '/edit-slot',
    viewSlot: '/view-slot',
    viewMedicine: '/view-medicine',
    doctor: '/doctor',
    addDoctor: '/add-doctor',
    editDoctor: '/edit-doctor',
    viewDoctor: '/view-doctor',
    viewPatientDoctor: '/view-patient-doctor',
    company: '/company',
    addCompany: '/add-company',
    editCompany: '/edit-company',
    viewCompany: '/view-company',
    companyUser: '/create-company-user',
    mapDevice: '/map-device',
    feeds: '/feeds',
    createFeed: '/create-feed',
    viewFeed: '/view-feed',
    editFeed: '/edit-feed',
    firmware: '/firmware',
    createFirmware: '/create-firmware',
    viewFirmware: '/view-firmware',
    editFirmware: '/edit-firmware',
    viewPatient: '/view-patient',
    getCompanyUser: '/company-user',
    viewCompanyUser: '/view-company-user',
    mappedUser: '/mapped-users',
    mappedCompany: '/mapped-company',
    caretaker: '/care-taker',
    addCareTaker: '/add-care-taker',
    editCareTaker: '/edit-care-taker',
    viewCareTaker: '/view-care-taker',
    caretakerMapping: '/view-care-taker-mapping',
    editCompanyUser: '/edit-company-user',
    viewAdherence: '/view-adherence',
    viewMedicineAdherence: '/view-medicine-adherence',
    addReferenceCode: '/add-reference-code',
    referenceCode: '/reference-codes',

    // company private
    compDashboard: '/company-dashboard',
    viewCompanyAdherence: '/view-company-adherence',
    viewCompMedicineAdherence: '/view-comp-medicine-adherence',
    compPatient: '/company-patient',
    compDoctor: '/company-doctor',
    compUsers: '/company-users',
    compViewPatient: '/view-company-patient',
    compViewMedicineAdherence: '/company-view-medicine-adherence',

    // doctor
    doctorDashboard: '/doctor-dashboard',
    viewDoctorAdherence: '/view-doctor-adherence',
    viewDocMedicineAdherence: '/view-doctor-medicine-adherence',
    docCaregiver: '/doctor-caregiver',
    viewDocCaregiver: '/view-doctor-caregiver',
    docViewPatient: '/view-doctor-patient',
    docPatient: '/doctor-patient',
    docCareTakerMapping: '/view-doctor-care-giver-mapping',
    docPatientMedicine: '/view-doctor-patient-medicine',

    // common

    updateProfile: '/update-profile',
    viewMedicineAdherenceDetails: '/medicine-adherence-details',

    // auth
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password/:token',
    changePassword: '/change-password'
};
