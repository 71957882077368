import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/table/Loader'
import slugs from 'resources/slugs';
import { bulkAddDevice } from 'resources/serverReq/admin/device';


export default function BulkAddDevice() {
    const history = useHistory();
    const [csv, setCSV] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileSelect = (e) => {
        setCSV(e.target.files[0]);
    };

    const handleFileUpload = async (event) => {
        if (!csv) {
            return;
        }
        setLoading(true);
        let formData = new FormData();
        formData.append('file', csv);
        bulkAddDevice(formData)
        .then((response) => {
            if (response?.data?.code == 200) {
                setCSV(null);
                toast.success('Successfully Uploaded', {
                    closeOnClick: true,
                    hideProgressBar: false
                });
                handleCancel();
            }
        })
        .catch((err) => {
            console.log(err.response?.data?.errorMessage?.errorDetails);
            toast.error(err.response?.data?.errorMessage?.message, {
                closeOnClick: true,
                hideProgressBar: false
            });
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleCancel = () => {
        history.push(slugs.device); 
    };

    return (
        <div className="container">
            {loading && <Loader />}
            <h3 className="mb-4">Add Multiple Devices</h3>
            <div className="mb-3">
                <input type="file" accept=".csv" onChange={handleFileSelect} />
            </div>
            <div>
                <button className="btn btn-secondary mr-2" onClick={handleCancel}>
                    Cancel
                </button>
                <button className="ms-3 btn btn-primary" onClick={handleFileUpload}>
                    Upload File
                </button>
            </div>
        </div>
    );
}
