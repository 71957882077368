import { FormBtns } from "components/table";
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getProfileDetails, getStates, updateProfile } from "resources/serverReq/common";
import * as Yup from "yup";
import "./common/common.css";


export default function UpdateProfile() {
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        dob: new Date().toISOString().slice(0, 10),
        gender: "",
        phone: "",
        city: "",
        role: "user",
        state: "",
        reference_code: "",
    });
    const [loading, setLoading] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const userSchema = Yup.object().shape({

        firstName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        lastName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        dob: Yup.date()
            .required("Required"),
        city: Yup.string()
            .required("Required"),
        state: Yup.string()
            .required("Required"),
        gender: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        phone: Yup.number()
            .min(10, "Minimum 10 symbols")
            .required("Required"),
        state: Yup.string()
            .required("Required"),
    });

    useEffect(() => {
        getStates().then(({ data }) => {
            const states = data.data.states;
            let options = []
            states.map(val => {
                options.push({
                    value: val.name,
                    label: val.name
                })
            });
            setStateOptions(options);
        }).catch(err => {
            console.log("🚀 ~ file: UpdateProfile.js ~ line 73 ~ useEffect ~ err", err)
        });


        getProfileDetails().then(({ data }) => {
            const user = data.data.user;
            console.log("🚀 ~ file: UpdateProfile.js ~ line 75 ~ getProfileDetails ~ user", user)
            setInitialValues(user);
        }).catch(err => {
            console.log("🚀 ~ file: UpdateProfile.js ~ line 77 ~ getProfileDetails ~ err", err)
        });

    }, [])

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: userSchema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            updateProfile(values).then((res) => {
                if (res?.data?.code === 200) {

                    localStorage.setItem("firstName", res?.data.data.user.firstName)
                    localStorage.setItem("lastName", res?.data.data.user.lastName)

                    toast.success('Your profile has been successfully updated.')
                }
                disableLoading();
                window.dispatchEvent(new Event("storage"));
                setSubmitting(false);
            })
                .catch((err) => {
                    console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage);
                });
        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <div className="container-xl d-flex justify-content-center align-items-center">
                        <form
                            onSubmit={formik.handleSubmit}

                        >
                            <div className='d-flex mt-5 '>

                                <div className="form-group form-input-2 mb-3">
                                    <label>First Name</label>
                                    <input
                                        placeholder="First Name"
                                        type="firstName"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "firstName"
                                        )}`}
                                        name="firstName"
                                        {...formik.getFieldProps("firstName")}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.firstName}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group form-input-2 mb-3">
                                    <label>Last Name</label>
                                    <input
                                        placeholder="Last Name"
                                        type="lastName"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "lastName"
                                        )}`}
                                        name="lastName"
                                        {...formik.getFieldProps("lastName")}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.lastName}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group form-input-2 mb-3">
                                <label>Email</label>
                                <input
                                    placeholder="Email"
                                    type="email"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "email"
                                    )}`}
                                    name="email"
                                    readOnly
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.email}</div>
                                    </div>
                                ) : null}
                            </div>

                            <div className='d-flex'>


                                <div className="form-group form-input-2 mb-3">
                                    <label>Date of Birth</label>
                                    <input
                                        placeholder="Date of Birth"
                                        type="date"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "dob"
                                        )}`}
                                        name="dob"
                                        {...formik.getFieldProps("dob")}
                                    />
                                    {formik.touched.dob && formik.errors.dob ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.dob}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group form-input-2 mb-3">
                                    <label>City</label>
                                    <input
                                        placeholder="City"
                                        type="city"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "city"
                                        )}`}
                                        name="city"
                                        {...formik.getFieldProps("city")}
                                    />
                                    {formik.touched.city && formik.errors.city ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.city}</div>
                                        </div>
                                    ) : null}
                                </div>

                            </div>

                            <div className='d-flex'>


                                <div className="form-group form-input-2 mb-3">
                                    <label>Phone</label>
                                    <input
                                        placeholder="Phone"
                                        type="text" pattern="\d*" maxLength={10}
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "phone"
                                        )}`}
                                        name="phone"
                                        readOnly
                                        {...formik.getFieldProps("phone")}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.phone}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group w-50">
                                    <label >Gender</label>
                                    <select placeholder="Gender"
                                        type="gender"
                                        className={`select-input form-control-solid ${getInputClasses(
                                            "gender"
                                        )} p-2`}
                                        name="gender"
                                        {...formik.getFieldProps("gender")}
                                    >
                                        <option disabled>Select..</option>
                                        <option value="male" >Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.gender}</div>
                                        </div>
                                    ) : null}

                                </div>

                            </div>

                            <div className='d-flex'>
                                <div className="form-group w-50">
                                    <label >State</label>
                                    <select placeholder="State"
                                        type="state"
                                        className={`select-input form-control-solid ${getInputClasses(
                                            "state"
                                        )} p-2`}
                                        name="state"
                                        {...formik.getFieldProps("state")}
                                    >
                                        <option >Select..</option>
                                        {stateOptions.map((el) => (
                                            <option key={el.value} value={el.value} >{el.value}</option>
                                        ))}
                                    </select>
                                    {formik.touched.state && formik.errors.state ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.state}</div>
                                        </div>
                                    ) : null}

                                </div>
                            </div>
                            <FormBtns formik={formik.isSubmitting} cancelRoute={""} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

