import { TableColumn, TableHeader } from 'components/table'
import Loader from 'components/table/Loader'
import Pagination from 'components/table/Pagination'
import moment from "moment"
import React, { useEffect, useState } from 'react'
import SLUGS from 'resources/slugs'
import { getAllCaretakerUserMapping } from "../../../resources/serverReq/doctor/user"
import "../../common/common.css"
import '../../table/table.css'

export default function DocCareTakerUserMapping() {
    let [userData, setUserData] = useState([]);
    const [perPageRows, setPerPageRows] = useState(7);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [status, setStatus] = useState("")
    const [state, setState] = useState("")
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const params = {
            page: page,
            search: query,
        };

        getAllCaretakerUserMapping(params).then((response) => {
            setIsLoading(true)
            setDataNotAvailable(false)
            if (response?.data?.code == 200) {
                const res = response?.data?.data.users
                setPageData({ count: res.count, currentPage: res.currentPage, totalPage: res.totalPage })
                setUserData(res.rows)
                if (res.count == 0) setDataNotAvailable(true)
            }
            setIsLoading(false)
        }).catch((err) => {
            console.log(err)
            setIsLoading(false)
            setDataNotAvailable(false)
        })


    }, [query, page])


    return (
        <>{
            !isLoading ?
                <div className="table-responsive">
                    <div className="table-wrapper">
                        <div className="table-title">
                            <TableHeader btnTitle={false} route={SLUGS.caretakerMapping} setQuery={setQuery} query={query} />
                        </div>
                        <table className="table table-borderless text-center">
                            <TableColumn columns={["Patient Name", "Email", "Phone", "Caretaker Name", "Email", "Phone", "Created On"]} />
                            <tbody>
                                {
                                    dataNotAvailable ? <tr><td></td><td></td><td></td><td>No Record Found</td><td></td><td></td><td></td><td></td></tr> :
                                        userData.map((el) => (
                                            <tr key={el.id}>
                                                <td>
                                                    <div className="d-flex flex-row bd-highlight p-2 ">
                                                        <span className='p-2'>
                                                            {el.patient.firstName + " " + el.patient.lastName}

                                                        </span>
                                                    </div>

                                                </td>

                                                <td>{el.patient.email}</td>
                                                <td>{el.patient.phone}</td>
                                                <td> {el.caretaker.firstName + " " + el.patient.lastName}</td>
                                                <td> {el.caretaker.email}</td>
                                                <td> {el.caretaker.phone}</td>
                                                <td>{moment(el.createdAt).format('DD-MMM-YY')}</td>
                                            </tr>
                                        ))}
                            </tbody>

                        </table>
                        <Pagination pageData={pageData} setPage={setPage} />
                    </div>
                </div> : <Loader />

        }

        </>
    )
}
