import { TableColumn, TableHeader } from 'components/table';
import Loader from 'components/table/Loader';
import Pagination from 'components/table/Pagination';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getAllReferenceCodes } from 'resources/serverReq/admin/reference-code';
import SLUGS from 'resources/slugs';
import '../../common/common.css';
import '../../table/table.css';

export default function ReferenceCode() {
    const [referenceCodeData, setReferenceCodeData] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [status, setStatus] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [csv, setCSV] = useState(null);
    const ref = useRef();
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        const params = {
            page: page,
            search: query,
            status: status,
            createdAt: createdAt
        };

        getAllReferenceCodes(params)
            .then((response) => {
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.referral_codes;
                    setReferenceCodeData(res.rows);
                    setPageData({
                        count: res.count,
                        currentPage: res.currentPage,
                        totalPage: res.totalPage
                    });
                    setDataNotAvailable(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [query, status, page, refreshKey, createdAt]);

    return (
        <>
            {query != '' || dataNotAvailable ? (
                <div className='table-responsive'>
                    <div className='table-wrapper'>
                        <TableHeader
                            btnTitle={'+ Add Referral Code '}
                            route={SLUGS.addReferenceCode}
                            setQuery={setQuery}
                            query={query}
                        />
                        <table className='table table-borderless text-center'>
                            <TableColumn
                                columns={[
                                    'Referral Code',
                                    'Referral Description',
                                    'Status',
                                    'Created On',
                                ]}
                            />
                            <tbody>
                                {referenceCodeData.length == 0 ? (
                                    <tr>
                                        <td></td>
                                        <td>No Record Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    referenceCodeData.map((el) => (
                                        <tr key={el.id}>
                                            <td>
                                                <div className='d-flex flex-row bd-highlight p-2 '>
                                                    <span className='p-2'>{el.code}</span>
                                                </div>
                                            </td>

                                            <td>{el.description}</td>
                                            <td>
                                                {el.status == true ? (
                                                    <span className='label label-lg label-light-success label-inline'>
                                                        Active
                                                    </span>
                                                ) : (
                                                    <span className='label label-lg label-light-danger label-inline'>
                                                        Inactive
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {moment(el.createdAt).format('DD-MMM-YY hh:mm a')}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination pageData={pageData} setPage={setPage} />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
