import { axios } from "../axios";

export async function getAllFirmwares(params) {
    return await axios.get("admin/firmware", {
        params
    });

}

export async function createFirmware(data) {
    return await axios.post(`/admin/firmware`, data);

}

export async function uploadMedia(data) {
    return await axios.post(`/admin/upload`, data);

}

export async function editFirmware(id, data) {
    return await axios.put(`/admin/firmware/${id}`, data);

}

export async function blockFirmware(id) {
    return await axios.put(`/admin/firmware/${id}/status`);

}
