import { axios } from "../axios";


export async function addCompany(data) {
    return await axios.post(`/admin/company`, data);

}

export async function updateCompany(id, data) {
    return await axios.put(`/admin/company/${id}`, data);

}

export async function getAllCompany(params) {
    return await axios.get("/admin/companies", {
        params
    });

}

export async function getAllUsers(id,params) {
    return await axios.get(`admin/company/${id}/users`, {
        params
    });

}

export async function updateUserInfo(id, data) {
    return await axios.put(`/admin/user/${id}`, data);

}

export async function addUserInfo(data) {
    return await axios.post(`/admin/user`, data);

}