import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { editSlot } from "resources/serverReq/admin/slot"
import slugs from 'resources/slugs';
import "../../common/common.css"
import { FormBtns } from "components/table";
import { toast } from 'react-toastify';


export default function EditSlot(props) {
    const data = props?.location?.state
    const initialValues = {
        name: data.name,
        type: data.type,
        startTime: data.startTime,
        endTime: data.endTime,
        order: data.order,
        displayName: data.displayName,
        displayType: data.displayType
    };

    const [loading, setLoading] = useState(false);
    const userScheema = Yup.object().shape({

        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        type: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        startTime: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        endTime: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        order: Yup.number()
            .required("Required"),
        displayName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        displayType: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: userScheema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            editSlot(data.id, values).then((res) => {
                if (res?.data?.code === 200) {
                    toast.success('Slot has been successfully updated.')
                    props.history.push(slugs.slot)
                }
                disableLoading();
            })
                .catch((err) => {
                    console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage, {
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
                });
        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>

                    <h3 className='d-flex justify-content-center align-items-center form-title mt-5 mb-5'>Update Slot</h3>
                    <div className="container-xl d-flex justify-content-center align-items-center">
                        <form
                            onSubmit={formik.handleSubmit}

                        >
                            <div className='d-flex '>

                                <div className="form-group form-input-2 mb-3">
                                    <label> Name</label>
                                    <input
                                        placeholder=" Name"
                                        type="name"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "name"
                                        )}`}
                                        name="name"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.name}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group form-input-2 mb-3">
                                    <label>Type</label>
                                    <input
                                        placeholder="Type"
                                        type="type"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "type"
                                        )}`}
                                        name="type"
                                        {...formik.getFieldProps("type")}
                                    />
                                    {formik.touched.type && formik.errors.type ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.type}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className='d-flex'>

                                <div className="form-group form-input-2 mb-3">
                                    <label>Display Name</label>

                                    <input
                                        placeholder="Display Name"
                                        type="displayName"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "displayName"
                                        )}`}
                                        name="displayName"
                                        {...formik.getFieldProps("displayName")}
                                    />
                                    {formik.touched.displayName && formik.errors.displayName ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.displayName}</div>
                                        </div>
                                    ) : null}
                                </div>


                                <div className="form-group form-input-2 mb-3">
                                    <label>Display Type</label>
                                    <input
                                        placeholder="Display Type"
                                        type="displayType"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "displayType"
                                        )}`}
                                        name="displayType"
                                        {...formik.getFieldProps("displayType")}
                                    />
                                    {formik.touched.displayType && formik.errors.displayType ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.displayType}</div>
                                        </div>
                                    ) : null}
                                </div>


                            </div>

                            <div className='d-flex'>
                                <div className="form-group form-input-2 mb-3">
                                    <label>Start Time</label>
                                    <input
                                        placeholder="Start Time"
                                        type="startTime"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "startTime"
                                        )}`}
                                        name="startTime"
                                        {...formik.getFieldProps("startTime")}
                                    />
                                    {formik.touched.startTime && formik.errors.startTime ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.startTime}</div>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="form-group form-input-2 mb-3">
                                    <label>End Time</label>
                                    <input
                                        placeholder="End Time"
                                        type="endTime"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "endTime"
                                        )}`}
                                        name="endTime"
                                        {...formik.getFieldProps("endTime")}
                                    />
                                    {formik.touched.endTime && formik.errors.endTime ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.endTime}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group form-input-2 mb-3">
                                    <label>Order</label>
                                    <input
                                        placeholder="Order"
                                        type="order"
                                        className={`form-control form-control-solid h-45px ${getInputClasses(
                                            "order"
                                        )}`}
                                        name="order"
                                        {...formik.getFieldProps("order")}
                                    />
                                    {formik.touched.order && formik.errors.order ? (
                                        <div>
                                            <div className="fv-help-block">{formik.errors.order}</div>
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.slot} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

