import React from 'react'
import { Link } from 'react-router-dom'

export function TableHeader({ btnTitle, route, setQuery, query, medicine, company, setCompany, companyName  }) {

    return (
        <>
            <div className="d-flex align-items-center">

                <div className="p-2  text-dark ">
                    {
                        btnTitle ?
                            <Link to={{
                                pathname: `${route}`
                            }} className={"active-invites-btn btn add-btn"} >{btnTitle}</Link> : ""
                    }
                </div>
                <div className="p-2  flex-grow-1 ">

                </div>

                <div className="search-box justify-content-end">

                    <input
                        onChange={(event) => setQuery(event.target.value)}
                        type="text"
                        value={query}
                        className="form-control"
                        placeholder={medicine ? "Search Medicine" : "Search…"} 
                        autoFocus
                    />
                </div>
                <div className="search-box justify-content-end">
                   {company && <input
                        onChange={(event) => setCompany(event.target.value)}
                        type="text"
                        value={companyName}
                        className="form-control"
                        placeholder="Search Company"
                        autoFocus
                    />}
                    </div>
            </div>

        </>
    )
}
