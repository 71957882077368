import React from 'react'
import { Link } from 'react-router-dom'

export function FormBtns({ formik, cancelRoute }) {
    return (
        <div className="form-group form-btns d-flex flex-wrap justify-content-center align-items-center" style={{ marginTop: "45px" }}>
            <button
                type="submit"
                disabled={formik}
                className={`btn referral-button`}
            >
                <span className='px-3'>Submit</span>
            </button>
            {
                cancelRoute ? <Link to={cancelRoute}
                    type="button"
                    className={`btn back-button`}
                >
                    <span className='px-3'>Cancel</span>
                </Link> : ""

            }
        </div>

    )
}
