import { FormBtns } from "components/table";
import { useFormik } from "formik";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { addMedicine } from "resources/serverReq/admin/medicine";
import slugs from 'resources/slugs';
import * as Yup from "yup";
import "../../common/common.css";


const initialValues = {
    name: "",
    companyName: "",
    code: "",
};

export default function AddMedicine(props) {
    const [loading, setLoading] = useState(false);
    const medicineSchema = Yup.object().shape({

        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
        companyName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(150, "Maximum 150 symbols")
            .required("Required"),
        code: Yup.string()
            .min(1, "Minimum 1 symbols")
            .max(150, "Maximum 150 symbols")
            .required("Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: medicineSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            addMedicine(values).then((res) => {
                if (res?.data?.code === 200) {
                    toast.success('Medicine has been successfully added in system.')
                    props.history.push(slugs.medicine)
                }
                disableLoading();
            })
                .catch((err) => {
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage, {
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
                });
        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>

                    <h3 className='d-flex justify-content-center align-items-center form-title mb-5'>Add Medicine</h3>
                    <div className="container-xl">
                        <form
                            onSubmit={formik.handleSubmit}

                        >

                            <div className="form-group form-input-2 mb-3">
                                <label>Medicine Name</label>
                                <input
                                    placeholder="Medicine Name"
                                    type="name"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "name"
                                    )}`}
                                    name="name"
                                    {...formik.getFieldProps("name")}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.name}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group form-input-2 mb-3">
                                <label>Company Name</label>
                                <input
                                    placeholder="Company Name"
                                    type="companyName"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "companyName"
                                    )}`}
                                    name="companyName"
                                    {...formik.getFieldProps("companyName")}
                                />
                                {formik.touched.companyName && formik.errors.companyName ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.companyName}</div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group form-input-2 mb-3">
                                <label>Medicine Code</label>
                                <input
                                    placeholder="Medicine Name"
                                    type="code"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "code"
                                    )}`}
                                    name="code"
                                    {...formik.getFieldProps("code")}
                                />
                                {formik.touched.code && formik.errors.code ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.code}</div>
                                    </div>
                                ) : null}
                            </div>
                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.medicine} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

