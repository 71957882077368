import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Row } from 'simple-flexbox';

const useStyles = createUseStyles((theme) => ({
    container: {
        marginLeft: 32,
        marginRight: 32
    },
    title: {
        ...theme.typography.cardTitle,
        color: theme.color.grayishBlue,
        opacity: 0.7,
        marginLeft: 12
    }
}));

function LogoComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row className={classes.container} horizontal='center' vertical='center'>
            <div>
                <img src="/assets/img/dose-logo-white.png" style={{ height: '5rem', padding: '0.5rem' }} />
            </div>
        </Row>
    );
}

export default LogoComponent;
