import { axios } from "../axios";


export async function getAllUsers(params) {
    return await axios.get("/admin/users", {
        params
    });

}

export async function updateUserInfo(id, data) {
    return await axios.put(`/admin/user/${id}`, data);

}

export async function updateProfile(data) {
    return await axios.post(`/user/me`, data);

}

export async function scheduleDoseByAdmin(data) {
    return await axios.post(`/admin/schedule-dose`, data);
}

export async function setDoseTimeByAdmin(data) {
    return await axios.post(`/admin/user-slots`, data);
}

export async function getUserSlotTimings(id) {
    return await axios.get(`/admin/user/${id}/user-slots`,);
}

export async function blockUsers(id) {
    return await axios.put(`/admin/user/${id}/status`);

}

export async function addUserInfo(data) {
    return await axios.post(`/admin/user`, data);

}

export async function getAllCaretakerUserMapping(params) {
    return await axios.get("/admin/caretaker-mapping", {
        params
    });

}

export async function getAllPatientsByDoctor(id, params) {
    return await axios.get(`/admin/doctor/${id}/patients`, {
        params
    });

}

export async function getUserDoses(id) {
    return await axios.get(`/admin/user/${id}/doses`,);
}

export async function getUserMedicineAdherence(id) {
    return await axios.get(`/admin/user/${id}/adherence`,);
}


