import React from 'react'

export default function Loader() {
    return (
        <div className="circle-progressbar">
            <div className="spinner-border" role="status" style={{ color: "rgb(29, 109, 167)" }}>
                <span className="sr-only" ></span>
            </div>
        </div>
    )
}
