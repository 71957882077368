import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import Login from 'components/auth/Login';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/ResetPassword';

function PublicRoutes() {
    return (
        <Switch>
            <Route path={SLUGS.login} component={Login} />
            <Route path={SLUGS.signup} render={() => <div>signup</div>} />
            <Route path={SLUGS.resetPassword} component={ResetPassword} />
            <Route path={SLUGS.forgotPassword} component={ForgotPassword} />
            <Redirect to={SLUGS.login} />
        </Switch>
    );
}

export default PublicRoutes;
