import { TableColumn, TableHeader, TableLastChild } from 'components/table';
import Loader from 'components/table/Loader';
import Pagination from 'components/table/Pagination';
import moment from 'moment';
import React , { useEffect, useRef, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    blockMedicine,
    getAllMedicine,
    uploadMedicineCsv
} from 'resources/serverReq/admin/medicine';
import SLUGS from 'resources/slugs';
import '../../common/common.css';
import '../../table/table.css';

export default function Medicine() {
    const [medicineData, setMedicineData] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [status, setStatus] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [csv, setCSV] = useState(null);
    const ref = useRef();
    const [refreshKey, setRefreshKey] = useState(0);
    const [sort, setSort] = useState(-1);
    const [sortBy, setSortBy] = useState('name');

    const handleSort = (field) => {
        if (sortBy === field) {
          setSort(sort === 1 ? -1 : 1);
        } else {
          setSortBy(field);
          setSort(1);
        }
      };

    useEffect(() => {
        const params = {
            page: page,
            medicineName: query,
            companyName: companyName,
            status: status,
            sort: sort,
            sortBy: sortBy,
            createdAt: createdAt
        };

        getAllMedicine(params)
            .then((response) => {
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.medicines;
                    setMedicineData(res.rows);
                    setPageData({
                        count: res.count,
                        currentPage: res.currentPage,
                        totalPage: res.totalPage
                    });
                    setDataNotAvailable(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [query, companyName, sort, sortBy, status, page, refreshKey, createdAt]);

    async function banMedicine(id) {
        await blockMedicine(id)
            .then((res) => {
                if (res?.data?.code === 200) {
                    const medicineList = medicineData.slice();
                    for (const medicine of medicineList) {
                        if (medicine.id === id) {
                            medicine.status = res?.data?.data?.medicine?.status;

                            break;
                        }
                    }
                    setMedicineData(medicineList);
                }
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }

    const handleFileSelect = (e) => {
        setCSV(e.target.files[0]);
    };

    const handleFileUpload = (e) => {
        if (csv != null) {
            let formData = new FormData();
            formData.append('file', csv);
            uploadMedicineCsv(formData)
                .then((response) => {
                    if (response?.data?.code == 200) {
                        ref.current.value = '';
                        setCSV(null);
                        setRefreshKey((oldKey) => oldKey + 1);
                        toast.success('Successfully Uploaded', {
                            closeOnClick: true,
                            hideProgressBar: false
                        });
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                    ref.current.value = '';
                    toast.error(err.response, {
                        closeOnClick: true,
                        hideProgressBar: false
                    });
                });
        }
    };
    return (
        <>
            {query != '' || dataNotAvailable ? (
                <div className='table-responsive'>
                    <div className='table-wrapper'>
                        <TableHeader
                            btnTitle={'+ Add Medicine '}
                            route={SLUGS.addMedicine}
                            setQuery={setQuery}
                            query={query}
                            medicine={true}
                            company={true}
                            companyName={companyName}
                            setCompany={setCompanyName}
                        />
                        <div className='m-2 text-dark'>
                            <input
                                className='w-25'
                                type='file'
                                accept='.csv'
                                ref={ref}
                                onChange={handleFileSelect}
                            />
                            <input
                                type='button'
                                className={'active-invites-btn btn add-btn'}
                                value='upload'
                                onClick={handleFileUpload}
                            />
                        </div>
                        <div className='d-flex form-group w-20'>
                            <div>
                                <label className='text-dark'>Status</label>
                                <select
                                    className='select-input p-2'
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                >
                                    <option disabled selected>Select</option>
                                    <option value='1'>Active</option>
                                    <option value='0'>Inactive</option>
                                </select>
                            </div>
                            <div className='mr-5'>
                                <label className='text-dark'>Date</label>
                                <br />
                                <input type='date' className='h-5' onChange={(e) => { setCreatedAt(e.target.value) }} />
                            </div>

                        </div>
                        <table className='table table-borderless text-center'>
                             <thead>
                                <tr>
                                    <th style={{cursor:"pointer"}}>
                                        Name
                                        <span onClick={() => handleSort('name')}>
                                        {sortBy === 'name' ? (sort === -1 ? ' ▲' : ' ▼') : ' ⇅'}
                                        </span>
                                    </th>
                                    <th>Company Name</th>
                                    <th>Status</th>
                                    <th style={{cursor:"pointer"}}>
                                        Created On
                                        <span onClick={() => handleSort('createdAt')}>
                                        {sortBy === 'createdAt' ? (sort === -1 ? ' ▲' : ' ▼') : ' ⇅'}
                                        </span>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {medicineData.length == 0 ? (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>No Record Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    medicineData.map((el) => (
                                        <tr key={el.id}>
                                            <td>
                                                <div className='d-flex flex-row bd-highlight p-2 '>
                                                    {/* <div className="d-flex align-items-start rounded-top">
                                                        <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                            <img className="image-position symbol-round"
                                                                src={
                                                                    "/assets/img/300_21.jpg"
                                                                }
                                                                alt="pic"
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <span className='p-2'>{el.name}</span>
                                                </div>
                                            </td>

                                            <td>{el.companyName}</td>
                                            <td>
                                                {el.status == true ? (
                                                    <span className='label label-lg label-light-success label-inline'>
                                                        Active
                                                    </span>
                                                ) : (
                                                    <span className='label label-lg label-light-danger label-inline'>
                                                        Inactive
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {moment(el.createdAt).format('DD-MMM-YY hh:mm a')}
                                            </td>
                                            <td className='d-flex'>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <Link
                                                        to={{
                                                            pathname: `${SLUGS.viewMedicine}/${el.id}`
                                                        }}
                                                        style={{ padding: '6px' }}
                                                        className='btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button'
                                                        title='View Details'
                                                    >
                                                        <span className='svg-icon menu-icon mr-2 text-light'>
                                                            <FaEye style={{ color: 'black' }} />
                                                        </span>
                                                    </Link>
                                                </div>
                                                <TableLastChild
                                                    editRoute={SLUGS.editMedicine}
                                                    editData={el}
                                                    block={banMedicine}
                                                    blockId={el.id}
                                                    BlockCondition={el.status}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination pageData={pageData} setPage={setPage} />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
