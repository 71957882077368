import { axios } from "../axios";


export async function getDoctorAdherence(params) {
    return await axios.get("/doctor/adherence", {
        params
    });
}

export async function getDoctorMedicineAdherence(params) {
    return await axios.get("/doctor/medicine-adherence", {
        params
    });
}