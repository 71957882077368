import {
    IconAgents, IconArticles, IconContacts, IconLogout, IconSubscription
} from 'assets/icons';
import { userContext } from "context/Provider";
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function DoctorSidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;
    const { user, setUser } = useContext(userContext)

    async function logout() {
        setUser(null)
        localStorage.clear()
        push(SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.doctorDashboard}
                title='Dashboard'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.doctorDashboard)}
            />
            <MenuItem
                id={SLUGS.viewDoctorAdherence}
                title='Adherence'
                items={[SLUGS.viewDoctorAdherence, SLUGS.viewDocMedicineAdherence]}
                icon={IconArticles}
            >
                <MenuItem
                    id={SLUGS.viewDoctorAdherence}
                    title='Adherence'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.viewDoctorAdherence)}
                />
                <MenuItem
                    id={SLUGS.viewDocMedicineAdherence}
                    title='Medicine'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.viewDocMedicineAdherence)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.docPatient}
                title='Patients'
                icon={IconAgents}
                onClick={() => onClick(SLUGS.docPatient)}
            />
            <MenuItem
                id={SLUGS.docCaregiver}
                title='Caregiver'
                icon={IconContacts}
                items={[SLUGS.docCaregiver, SLUGS.docCareTakerMapping]}
            >
                <MenuItem
                    id={SLUGS.docCaregiver}
                    title='Caregiver'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.docCaregiver)}
                />
                <MenuItem
                    id={SLUGS.docCareTakerMapping}
                    title='Caregiver Patients'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.docCareTakerMapping)}
                />
            </MenuItem>

            <div className={classes.separator}></div>

            <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={logout} />
        </Menu>
    );
}

export default DoctorSidebarComponent;
