import { FormBtns } from 'components/table';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { editFeed, uploadMedia } from 'resources/serverReq/admin/feed';
import { editFirmware } from 'resources/serverReq/admin/firmware';
import slugs from 'resources/slugs';
import * as Yup from 'yup';
import '../../common/common.css';
import './style.css';

export default function EditFirmware(props) {
    const [fileNames, setFileNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [media, setMedia] = useState(null);
    const [mediaType, setMediaType] = useState('');
    const handleDrop = (acceptedFiles) => {
        setFileNames(acceptedFiles.map((file) => file.name));
        setMedia(acceptedFiles[0]);
    };
    const data = props.location.state;
    console.log('🚀 ~ file: EditFeed.js ~ line 21 ~ EditFeed ~ data', data);
    const initialValues = {
        version: data.version,
        fileUrl: data.fileUrl,
        remark: data.remark
    };

    useEffect(() => {
        setMediaType(data.type);
        setFileNames([data.url]);
    }, []);

    const firmwareSchema = Yup.object().shape({
        version: Yup.string().required('Required').max(7).min(7)
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return 'is-invalid';
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return 'is-valid';
        }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: firmwareSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            const mediaFormData = new FormData();
            if (media) {
                mediaFormData.append('profile_picture', media, media.name);
                uploadMedia(mediaFormData)
                    .then((res) => {
                        setFileNames([res?.data?.data?.url]);
                        if (res?.data?.code === 200) {
                            const payload = {
                                version: values.version,
                                fileUrl: res?.data?.data?.url
                            };
                            editFirmware(data.id, payload)
                                .then((res) => {
                                    if (res?.data?.code === 200) {
                                        toast.success('Firmware edited in system');
                                        props.history.push(slugs.firmware);
                                    }
                                    disableLoading();
                                })
                                .catch((err) => {
                                    disableLoading();
                                    setSubmitting(false);
                                    const errorMessage = err.response.data.errorMessage;
                                    toast.error(errorMessage, {
                                        closeOnClick: true,
                                        hideProgressBar: false
                                    });
                                });
                        }
                    })
                    .catch((err) => {
                        disableLoading();
                        setSubmitting(false);
                        const errorMessage = err.response.data.errorMessage;
                        toast.error(errorMessage, {
                            closeOnClick: true,
                            hideProgressBar: false
                        });
                    });
            } else {
                const payload = {
                    version: values.title,
                    fileUrl: values.url
                };
                editFirmware(data.id, payload)
                    .then((res) => {
                        if (res?.data?.code === 200) {
                            toast.success('Frimware updated in system');
                            props.history.push(slugs.feeds);
                        }
                        disableLoading();
                    })
                    .catch((err) => {
                        disableLoading();
                        setSubmitting(false);
                        const errorMessage = err.response.data.errorMessage;
                        toast.error(errorMessage, {
                            closeOnClick: true,
                            hideProgressBar: false
                        });
                    });
            }
        }
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>
                    <h3 className='d-flex justify-content-center align-items-center form-title mt-5 mb-5'>
                        Update Firmware
                    </h3>
                    <div className='container-xl'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='form-group form-input-2 mb-3'>
                                <label>Version</label>
                                <input
                                    placeholder='X.X.X.X'
                                    type='version'
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        'version'
                                    )}`}
                                    name='version'
                                    {...formik.getFieldProps('version')}
                                />
                                {formik.touched.version && formik.errors.version ? (
                                    <div>
                                        <div className='fv-help-block'>{formik.errors.version}</div>
                                    </div>
                                ) : null}
                            </div>

                            <div className='form-group form-input-2 mb-3'>
                                <label>Whats New</label>
                                <textarea
                                    placeholder='Bug fixes ....'
                                    rows={5}
                                    className={`form-control form-control-solid ${getInputClasses(
                                        'remark'
                                    )}`}
                                    name='remark'
                                    {...formik.getFieldProps('remark')}
                                />
                                {formik.touched.remark && formik.errors.remark ? (
                                    <div>
                                        <div className='fv-help-block'>{formik.errors.remark}</div>
                                    </div>
                                ) : null}
                            </div>   

                            <div className='mt-3'>
                                <Dropzone
                                    multiple={false}
                                    onDrop={handleDrop}
                                    accept='application/zip'
                                    minSize={1024}
                                    maxSize={3072000}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>Drag'n'drop zip, or click to select files</p>
                                        </div>
                                    )}
                                </Dropzone>
                                <div>
                                    <strong>Files:</strong>
                                    <ul>
                                        {fileNames.map((fileName) => (
                                            <li key={fileName}>
                                                <p>{fileName}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.firmware} />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
