import Loader from 'components/table/Loader';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RangePicker } from 'react-minimal-datetime-range';
import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';
import { getAllCompany } from 'resources/serverReq/admin/company';
import { getStates } from 'resources/serverReq/common';
import MiniCardComponent from '../../../components/cards/MiniCardComponent';
import { getAdminAdherence } from '../../../resources/serverReq/admin/adherence';

const useStyles = createUseStyles({
    cardsContainer: {
        marginRight: -30,
        marginTop: -30
    },
    cardRow: {
        marginTop: 30,
        '@media (max-width: 768px)': {
            marginTop: 0
        }
    },
    miniCardContainer: {
        flexGrow: 1,
        marginRight: 30,
        marginTop: 10,
        '@media (max-width: 768px)': {
            marginTop: 30,
            maxWidth: 'none'
        }
    },
    todayTrends: {
        marginTop: 30
    },
    lastRow: {
        marginTop: 30
    },
    unresolvedTickets: {
        marginRight: 30,
        '@media (max-width: 1024px)': {
            marginRight: 0
        }
    },
    tasks: {
        marginTop: 0,
        '@media (max-width: 1024px)': {
            marginTop: 30
        }
    }
});

export default function Adherence() {
    const [state, setState] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [adherence, setAdherence] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [stateOptions, setStateOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const classes = useStyles();
    const now = new Date();
    const [date, setDate] = useState(String(now.getDate()).padStart(2, '0'));
    const [month, setMonth] = useState(String(now.getMonth() + 1).padStart(2, '0'));
    const [startDate, setStartDate] = useState(String(now.getDate()).padStart(2, '0'));
    const [endDate, setEndDate] = useState(null);
    const [year, setYear] = useState(String(now.getFullYear()));
    const [companyId, setCompanyId] = useState("");
    const [defaultDate, setDefaultDate] = useState([
        year + '-' + month + '-' + date,
        year + '-' + month + '-' + date
    ])


    const handleStateChange = (event) => {
        event.preventDefault();
        setState(event.target.value);
    };

    const handleCompanyChange = (event) => {
        console.log("🚀 ~ file: Adherence.js ~ line 84 ~ handleCompanyChange ~ event", event.target)
        event.preventDefault();
        setCompanyId(event.target.value);
    }
    useEffect(() => {

        getAllCompany()
            .then(({ data }) => {
                const companies = data.data.companies.rows;
                let options = [];
                companies.map((val) => {
                    options.push({
                        value: val.id,
                        label: val.name
                    });
                });
                setCompanyOptions(options);
            })
            .catch((err) => {
                console.log("🚀 ~ file: Adherence.js ~ line 96 ~ useEffect ~ err", err)
            });

        getStates()
            .then(({ data }) => {
                const states = data.data.states;
                let options = [];
                states.map((val) => {
                    options.push({
                        value: val.name,
                        label: val.name
                    });
                });
                setStateOptions(options);
            })
            .catch((err) => {
                console.log('🚀 ~ file: AddPatient.js ~ line 73 ~ useEffect ~ err', err);
            });
    }, []);

    useEffect(() => {
        const params = {
            state: state,
            age: age,
            gender: gender,
            to: endDate,
            from: startDate,
            company_id: companyId
        };

        getAdminAdherence(params)
            .then((response) => {
                setIsLoading(true);
                if (response?.data?.code == 200) {
                    const res = response?.data?.data;
                    setAdherence(res);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [state, gender, age, endDate, companyId]);

    const handleDateSelected = (res) => {
        console.log("🚀 ~ file: Adherence.js ~ line 124 ~ handleDateSelected ~ res", res)
        const start = res[0].replace(":", "").trim();
        console.log("🚀 ~ file: Adherence.js ~ line 122 ~ handleDateSelected ~ start", start)
        const end = res[1].replace(":", "").trim();
        setStartDate(start)
        setEndDate(end)
        setDefaultDate([start, end])
    }

    return (
        <>
            {!isLoading ? (
                <>
                    <div className='table-responsive'>
                        <div className='table-wrapper'>
                            <div className='table-title'>
                                <div className='d-flex float-right'>
                                    <div className='form-group w-15'>
                                        <label className='text-dark'>State</label>
                                        <select
                                            placeholder='State'
                                            type='state'
                                            className={`select-input form-control-solid p-2`}
                                            name='state'
                                            onChange={handleStateChange}
                                            value={state}
                                        >
                                            <option value="">Select..</option>
                                            {stateOptions.map((el) => (
                                                <option key={el.value}>{el.value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label className='text-dark'>Gender</label>
                                        <select
                                            className='select-input p-2'
                                            onChange={(e) => setGender(e.target.value)}
                                            value={gender}
                                        >
                                            <option value="">Select</option>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label className='text-dark'>Age</label>
                                        <select
                                            className='select-input p-2'
                                            onChange={(e) => setAge(e.target.value)}
                                            value={age}
                                        >
                                            <option value="">Select</option>
                                            <option value='1'>&gt;70</option>
                                            <option value='2'>60 to 70</option>
                                            <option value='3'>50 to 60</option>
                                            <option value='4'>40 to 50</option>
                                            <option value='5'>&lt; 40</option>
                                        </select>
                                    </div>
                                    <div className='form-group w-15'>
                                        <label className='text-dark'>Company</label>
                                        <select
                                            placeholder='Company'
                                            type='company'
                                            className={`select-input form-control-solid p-2`}
                                            name='company'
                                            onChange={handleCompanyChange}
                                            value={companyId}
                                        >
                                            <option value="">Select..</option>
                                            {companyOptions.map((el) => (
                                                <option key={el.value} value={el.value}>{el.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex' style={{ margin: '18px' }}>
                        <div className='row'>
                            <RangePicker
                                locale={`en-us`} // default is en-us
                                show={false} // default is false
                                disabled={false} // default is false
                                allowPageClickToClose={true} // default is true
                                placeholder={['Start Date', 'End Date']}
                                defaultDates={defaultDate} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                // initialDates={[
                                //     year + '-' + month + '-' + date,
                                //     year + '-' + month + '-' + date
                                // ]} // ['YYYY-MM-DD', 'YYYY-MM-DD']
                                onConfirm={(res) => handleDateSelected(res)}
                                onClose={() => console.log('closed')}
                                style={{ width: '300px', margin: '0 auto' }}
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='row'>
                            <MiniCardComponent
                                className={classes.miniCardContainer}
                                title='Total Patient'
                                value={parseInt(adherence.bucket_100_80) + parseInt(adherence.bucket_80_50) + parseInt(adherence.bucket_50_30) + parseInt(adherence.bucket_30)}
                            />
                            <MiniCardComponent
                                className={classes.miniCardContainer}
                                title='Avg Adherence'
                                value={adherence.avg_adherence}
                            />
                            <div className='row'></div>
                            <MiniCardComponent
                                className={classes.miniCardContainer}
                                title='100% - 80 %'
                                value={adherence.bucket_100_80}
                            />
                            <MiniCardComponent
                                className={classes.miniCardContainer}
                                title='80% - 50 %'
                                value={adherence.bucket_80_50}
                            />
                            <MiniCardComponent
                                className={classes.miniCardContainer}
                                title='50% - 30%'
                                value={adherence.bucket_50_30}
                            />
                            <MiniCardComponent
                                className={classes.miniCardContainer}
                                title='< 30%'
                                value={adherence.bucket_30}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}
