import { IconBlock, IconEdit, IconSchedule } from 'assets/icons';
import React from 'react';
import { FaEye, FaUndoAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

export function TableLastChild({ editRoute, editData, block, blockId, BlockCondition, path, schedule, scheduleRoute }) {
    return (
        <>

            <div className='d-flex justify-content-start last-child-table'>
                {
                    path ? <Link to={{
                        pathname: `${path}`,
                        state: editData
                    }}
                        style={{ padding: "6px" }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button btn-size"
                        title="View details"

                    >
                        <span className="svg-icon menu-icon text-light">
                            <FaEye style={{ color: 'black' }} />
                        </span>

                    </Link> : ""
                }

                {
                    editRoute ?
                        <Link to={{
                            pathname: `${editRoute}`,
                            state: editData

                        }}
                            title="Edit"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button ml-5 btn-size"

                        >
                            <span className="svg-icon svg-icon-sm svg-icon-primary">
                                <IconEdit />
                            </span>
                        </Link> : ""
                }

                {
                    blockId ? <a
                        title={BlockCondition ? "Disable" : "Enable"}
                        className="btn btn-icon btn-light btn-hover-warning btn-sm svg-icon-button ml-5 btn-size"
                        onClick={() => block(blockId)}
                    >
                        {
                            BlockCondition ? <span className="svg-icon svg-icon-sm svg-icon-warning">
                                <IconBlock />
                            </span> : <span className="svg-icon svg-icon-sm svg-icon-warning">
                                <span className="svg-icon svg-icon-sm svg-icon-danger">
                                    <FaUndoAlt />
                                </span>
                            </span>


                        }

                    </a> : ""
                }

                {
                    schedule ?  <Link to={{
                        pathname: `${scheduleRoute}`,
                        state: editData
                    }}
                        title="Schedule Dose"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button ml-5 btn-size"

                    >
                        <span className="svg-icon svg-icon-sm svg-icon-primary">
                            <IconSchedule />
                        </span>
                    </Link> : ""
                }

            </div>

        </>
    )
}
