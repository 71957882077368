import { axios } from "../axios";


export async function getAllReferenceCodes(params) {
    return await axios.get("/admin/reference-codes", {
        params
    });

}

export async function addReferenceCode(data) {
    return await axios.post(`/admin/reference-codes`, data);

}

export async function blockReferenceCodes(id) {
    return await axios.put(`/admin/reference-codes/${id}/status`);
}

