import { FormBtns } from "components/table";
import { useFormik } from "formik";
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { updateCompany } from "resources/serverReq/admin/company";
import slugs from 'resources/slugs';
import * as Yup from "yup";
import "../../common/common.css";


export default function EditCompany(props) {

    console.log("=========>", props)

    const data = props?.location?.state
    const initialValues = {
        name: data.name,
    };

    const [loading, setLoading] = useState(false);
    const companySchema = Yup.object().shape({

        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: companySchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            console.log("************", values)
            updateCompany(data.id, values).then((res) => {

                console.log("res==========>", res)

                if (res?.data?.code === 200) {
                    toast.success('Company edited in system')
                    props.history.push(slugs.company)
                }
                disableLoading();
            })
                .catch((err) => {
                    console.log("🚀 ~ file: dit-user.js ~ line 119 ~ dit-user ~ err.response", err.response)
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage);
                });
        },
    });

    return (
        <>
            <div className='row'>
                <div className='card col-md-6 offset-md-3'>

                    <h3 className='d-flex justify-content-center align-items-center form-title mt-5 mb-5'>Edit Company</h3>
                    <div className="container-xl">
                        <form
                            onSubmit={formik.handleSubmit}

                        >

                            <div className="form-group form-input-2 mb-3">
                                <label>Device Name</label>
                                <input
                                    placeholder="Device Name"
                                    type="name"
                                    className={`form-control form-control-solid h-45px ${getInputClasses(
                                        "name"
                                    )}`}
                                    name="name"
                                    {...formik.getFieldProps("name")}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div>
                                        <div className="fv-help-block">{formik.errors.name}</div>
                                    </div>
                                ) : null}
                            </div>
                            <FormBtns formik={formik.isSubmitting} cancelRoute={slugs.company} />

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

