import React, { useState } from 'react';
import "./PatientView.css"

const PatientRow = ({ medicineAdherenceDetails }) => {
  const [selectedMedicineId, setSelectedMedicineId] = useState(null);

  const toggleMedicineDetails = (medicineId) => {
    if (selectedMedicineId === medicineId) {
      setSelectedMedicineId(null);
    } else {
      setSelectedMedicineId(medicineId);
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
    return formattedDate;
  };

  const formatTime = (inputTime) => {
    const [hours, minutes] = inputTime.split(':');
    let formattedHours = parseInt(hours, 10) % 12;
    formattedHours = formattedHours === 0 ? 12 : formattedHours;
    const period = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    const formattedTime = `${formattedHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const formatSlot = (slotId) => {
    switch (slotId) {
      case 1:
      case 2:
        return 'Morning';
      case 3:
      case 4:
        return 'Afternoon';
      case 5:
      case 6:
        return 'Evening';
      case 7:
      case 8:
        return 'Night';
      default:
        return 'Unknown'; 
    }
  };

  const renderBoxEntries = (openBox, missedBox) => {
    const allEntries = [...openBox, ...missedBox];
  
    const groupedEntries = {};
  
    allEntries.forEach((box) => {
      const dateKey = box.date;
      if (!groupedEntries[dateKey]) {
        groupedEntries[dateKey] = {
          entries: [],
          openCount: 0,
          missedCount: 0,
        };
      }
      groupedEntries[dateKey].entries.push(box);
      if (box.status === 'open') {
        groupedEntries[dateKey].openCount += 1;
      } else {
        groupedEntries[dateKey].missedCount += 1;
      }
    });
  
    return Object.keys(groupedEntries).map((date) => (
      <tr key={date}>
        <td>{formatDate(date)}</td>
        <td>
          {groupedEntries[date].entries.map((box) => (
            <span key={box.id} className={`${box.status === 'open' ? 'text-success' : 'text-danger'} me-3`}>
              {formatTime(box.time)}
            </span>
          ))}
        </td>
        <td>{groupedEntries[date].openCount}</td>
        <td>{groupedEntries[date].missedCount}</td>
      </tr>
    ));
  };

  return (
    <div className='row justify-content-center medicine-history-table'>
      <div className="card col-md-12">
        <div className='card-body'>
          <h5 className="card-title">Medicine History</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Company Name</th>
                <th>Total Open</th>
                <th>Total Missed</th>
              </tr>
            </thead>
            <tbody>
              {medicineAdherenceDetails.length > 0 ? medicineAdherenceDetails.map((medicine) => (
                <React.Fragment key={medicine.medicineId}>
                  <tr onClick={() => toggleMedicineDetails(medicine.medicineId)} style={{ cursor: 'pointer' }}>
                    <td className='medicine-name label label-lg label-light-warning label-inline'>{medicine.medicineName}</td>
                    <td>{medicine.companyName}</td>
                    <td>{medicine.totalOpen}</td>
                    <td>{medicine.totalMissed}</td>
                  </tr>
                  {/* Render detailed history if medicine is selected */}
                  {selectedMedicineId === medicine.medicineId && (
                    <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Taken Count</th>
                        <th>Missed Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderBoxEntries(medicine.openBox, medicine.missedBox)}
                    </tbody>
                  </table>
                  )}
                </React.Fragment>
              )) : <tr><td><td> No Record Found </td></td></tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PatientRow;
