import { useFormik } from "formik";
import React, { useState } from "react";
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { forgotPass } from "../../resources/serverReq/admin/auth";

const initialValues = {
    email: ""
};

const useStyles = createUseStyles((theme) => ({
    title: {
        ...theme.typography.title,
        color: theme.color.veryDarkGrayishBlue,
        textAlign: 'center',
        margin: '20px 10px'
    },
    itemValue: {
        color: theme.color.grayishBlue2
    },
    greyTitle: {
        color: theme.color.grayishBlue3
    },
    checkboxWrapper: {
        cursor: 'pointer',
        marginRight: 16
    },
    forgotForm: {
        width: '350px',
        margin: 'auto',
        // padding: '30px 0',
        marginTop: '10%',
    },
    form: {
        color: '#434343',
        borderRadius: '1px',
        marginBottom: '15px',
        background: '#fff',
        border: '1px solid #f3f3f3',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
        padding: '30px',
    },
    avatar: {
        color: '#fff',
        margin: '0 auto 30px',
        textAlign: 'center',
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        zIndex: 9,
        background: 'blue',
        padding: '15px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    },
    forgotPassword: {
        float: 'right'
    }
}));


function ForgotPassword(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const forgotSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Required"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: forgotSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();

            forgotPass(values).then((res) => {
                disableLoading();
                toast.info("Email has been sent successfully", {
                    closeOnClick: true,
                    hideProgressBar: false,
                })
            })
                .catch((err) => {
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage, {
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
                });
        },
    });

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className={classes.forgotForm}>
                <form
                    onSubmit={formik.handleSubmit}
                    className={classes.form}
                >
                    {formik.status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : null}

                    <div className="form-group mb-3">
                        <h1 className={classes.title}>Forgot Password to your account</h1>
                        {/* <div className={classes.avatar}>Logo</div> */}
                        <div className="d-flex justify-content-center align-items-center">

                            <img src="assets/img/dose-logo.png" style={{ width: "50%", marginBottom: "20px" }} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <label className="text-muted mb-2" style={{ fontSize: '0.8rem' }}>
                                enter your registered email address
                            </label>
                        </div>
                        <input
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            required
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn w-100 text-light`}
                            style={{ background: "#006FD8" }}
                        >
                            {!loading ? 'Send Mail' : <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
