import { IconAgents, IconArticles, IconContacts, IconLogout, IconSubscription } from 'assets/icons';
import { userContext } from 'context/Provider';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function CompanySidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;
    const { user, setUser } = useContext(userContext);

    async function logout() {
        setUser(null);
        localStorage.clear();
        push(SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.compDashboard}
                title='Dashboard'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.compDashboard)}
            />
            <MenuItem
                id={SLUGS.viewCompanyAdherence}
                title='Adherence'
                items={[
                    SLUGS.viewCompanyAdherence,
                    SLUGS.viewCompMedicineAdherence,
                    SLUGS.viewMedicineAdherenceDetails
                ]}
                icon={IconArticles}
            >
                <MenuItem
                    id={SLUGS.viewCompanyAdherence}
                    title='Adherence'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.viewCompanyAdherence)}
                />
                <MenuItem
                    id={SLUGS.viewCompMedicineAdherence}
                    title='Medicine'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.viewCompMedicineAdherence)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.compPatient}
                title='Patients'
                icon={IconAgents}
                onClick={() => onClick(SLUGS.compPatient)}
            />

            <MenuItem
                id={SLUGS.compDoctor}
                title='Doctors'
                icon={IconContacts}
                onClick={() => onClick(SLUGS.compDoctor)}
            />

            <MenuItem
                id={SLUGS.compUsers}
                title='Company Users'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.compUsers)}
            />
            <div className={classes.separator}></div>

            <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={logout} />
        </Menu>
    );
}

export default CompanySidebarComponent;
