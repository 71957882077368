import { axios } from "../axios";

export async function getAllFeeds(params) {
    return await axios.get("admin/feeds", {
        params
    });

}

export async function createFeed(data) {
    return await axios.post(`/admin/feed`, data);

}

export async function uploadMedia(data) {
    return await axios.post(`/admin/upload`, data);

}

export async function editFeed(id, data) {
    return await axios.put(`/admin/feed/${id}`, data);

}

export async function blockFeed(id) {
    return await axios.put(`/admin/feed/${id}/status`);

}
