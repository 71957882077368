import { TableColumn, TableHeader, TableLastChild } from 'components/table';
import Loader from 'components/table/Loader';
import Pagination from 'components/table/Pagination';
import moment from 'moment';
import React,{ useEffect, useState } from 'react';
import { blockFeed, blockFirmware, getAllFeeds } from 'resources/serverReq/admin/firmware';
import SLUGS from 'resources/slugs';
import '../../common/common.css';
import '../../table/table.css';
import { getAllFirmwares } from 'resources/serverReq/admin/firmware';

export default function Firmwares() {
    const [firmwareData, setFirmwareData] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [status, setStatus] = useState('');
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let params = {
            page: page,
            search: query,
            status: status
        };

        if (status === "Select"){
            params = {
                page: page,
                search: query,
            }
        }

        getAllFirmwares(params)
            .then((response) => {
                setIsLoading(true);
                setDataNotAvailable(false);
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.firmwares;
                    setFirmwareData(res.rows);
                    setPageData({
                        count: res.count,
                        currentPage: res.currentPage,
                        totalPage: res.totalPage
                    });
                    if (res.count == 0) setDataNotAvailable(true);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setDataNotAvailable(false);
            });
    }, [query, status, page]);

    async function statusUpdateFirmware(id) {
        await blockFirmware(id)
            .then((res) => {
                if (res.data.code === 200) {
                    console.log('🚀 ~ file: Feeds.js ~ line 47 ~ awaitblockFeed ~ res', res);
                    const firmwareList = firmwareData.slice();
                    for (const firmware of firmwareList) {
                        if (firmware.id === id) {
                            firmware.status = res?.data?.data?.firmware?.status;

                            break;
                        }
                    }
                    setFirmwareData(firmwareList);
                }
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }

    return (
        <>
            {!isLoading ? (
                <div className='table-responsive'>
                    <div className='table-wrapper'>
                        <TableHeader
                            btnTitle={'+ Add Firmware '}
                            route={SLUGS.createFirmware}
                            setQuery={setQuery}
                            query={query}
                        />
                        <div className='form-group w-15'>
                            <label className='text-dark'>Status</label>
                            <select
                                className='select-input p-2'
                                onChange={(e) => setStatus(e.target.value)}
                                value={status}
                            >
                                <option>Select</option>
                                <option value='1'>Active</option>
                                <option value='0'>Inactive</option>
                            </select>
                        </div>
                        <table className='table table-borderless text-center'>
                            <TableColumn
                                columns={[
                                    'Version',
                                    'FileUrl',
                                    'Status',
                                    'Created On',
                                    'Action'
                                ]}
                            />

                            <tbody>
                                {dataNotAvailable ? (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>No Record Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    firmwareData.map((el) => (
                                        <tr key={el.id}>
                                            <td>
                                                <div className='d-flex flex-row bd-highlight p-2 '>
                                                    {/* <div className="d-flex align-items-start rounded-top">
                                                                <div className="symbol symbol-md bg-light-primary flex-shrink-0">
                                                                    <img className="image-position symbol-round"
                                                                        src={
                                                                            "/assets/img/300_21.jpg"
                                                                        }
                                                                        alt="pic"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                    <span className='p-2'>{el.version}</span>
                                                </div>
                                            </td>
                                            <td className='firmware-description'>
                                                <a href={el.fileUrl} target='_blank'>Download File</a>
                                            </td>                                            
                                            <td>
                                                {el.status == true ? (
                                                    <span className='label label-lg label-light-success label-inline'>
                                                        Active
                                                    </span>
                                                ) : (
                                                    <span className='label label-lg label-light-danger label-inline'>
                                                        Inactive
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                {moment(el.createdAt).format('DD-MMM-YY hh:mm a')}
                                            </td>
                                            <td>
                                                <div className='d-flex'>
                                                    <TableLastChild
                                                        editRoute={SLUGS.editFirmware}
                                                        editData={el}
                                                        block={statusUpdateFirmware}
                                                        blockId={el.id}
                                                        BlockCondition={el.status}
                                                        path={SLUGS.viewFirmware}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination pageData={pageData} setPage={setPage} />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
