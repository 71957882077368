import { userContext } from "context/Provider";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { changePassword } from '../../resources/serverReq/common';

const initialValues = {
    confirmPassword: "",
    password: "",
    old_password: ""
};

const useStyles = createUseStyles((theme) => ({
    title: {
        ...theme.typography.title,
        color: theme.color.veryDarkGrayishBlue,
        textAlign: 'center',
        margin: '20px 10px'
    },
    itemValue: {
        color: theme.color.grayishBlue2
    },
    greyTitle: {
        color: theme.color.grayishBlue3
    },
    checkboxWrapper: {
        cursor: 'pointer',
        marginRight: 16
    },
    ChangePasswordForm: {
        width: '350px',
        margin: 'auto',
        // padding: '30px 0',
        marginTop: '10%',
    },
    form: {
        color: '#434343',
        borderRadius: '1px',
        marginBottom: '15px',
        background: '#fff',
        border: '1px solid #f3f3f3',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
        padding: '30px',
    },
    avatar: {
        color: '#fff',
        margin: '0 auto 30px',
        textAlign: 'center',
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        zIndex: 9,
        background: 'blue',
        padding: '15px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    },
    forgotPassword: {
        float: 'right'
    }
}));


function ChangePassword(props) {
    const classes = useStyles();
    const { user, setUser } = useContext(userContext)
    const [loading, setLoading] = useState(false);
    const ChangePasswordSchema = Yup.object().shape({
        old_password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(16, "Maximum 16 symbols")
            .required("Required"),
        password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(16, "Maximum 16 symbols")
            .required("Required"),
        confirmPassword: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(16, "Maximum 16 symbols")
            .required("Required")
            .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ChangePasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            enableLoading();
            changePassword({ oldPassword: values.old_password, newPassword: values.password }).then((res) => {
                disableLoading();
                toast.success("Password updated successfully", {
                    closeOnClick: true,
                    hideProgressBar: false,
                })
                resetForm();
            })
                .catch((err) => {
                    disableLoading();
                    setSubmitting(false);
                    const errorMessage = err.response.data.errorMessage;
                    toast.error(errorMessage, {
                        closeOnClick: true,
                        hideProgressBar: false,
                    })
                });
        },
    });

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className={classes.ChangePasswordForm}>
                <form
                    onSubmit={formik.handleSubmit}
                    className={classes.form}
                >
                    {formik.status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : null}

                    <div className="form-group mb-3">
                        <input
                            placeholder="Old Password"
                            type="password"
                            className={`form-control form-control-solid ${getInputClasses(
                                "old_password"
                            )}`}
                            name="old_password"
                            {...formik.getFieldProps("old_password")}
                        />
                        {formik.touched.old_password && formik.errors.old_password ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.old_password}</div>
                            </div>
                        ) : null}

                    </div>
                    <div className="form-group mb-3">
                        <input
                            placeholder="Password"
                            type="password"
                            className={`form-control form-control-solid ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}

                    </div>
                    <div className="form-group mb-3">
                        <input
                            placeholder="Confirm Password"
                            type="password"
                            className={`form-control form-control-solid ${getInputClasses(
                                "confirmPassword"
                            )}`}
                            name="confirmPassword"
                            {...formik.getFieldProps("confirmPassword")}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                            </div>
                        ) : null}

                    </div>


                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn w-100 text-light`}
                            style={{ background: "#006FD8" }}
                        >
                            {!loading ? 'Change Password' : <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword;
