import { userContext } from "context/Provider";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import slugs from 'resources/slugs';
import * as Yup from "yup";
import { resetPassword, verifyResetToken } from '../../resources/serverReq/admin/auth';

const initialValues = {
    confirmPassword: "",
    password: "",
};

const useStyles = createUseStyles((theme) => ({
    title: {
        ...theme.typography.title,
        color: theme.color.veryDarkGrayishBlue,
        textAlign: 'center',
        margin: '20px 10px'
    },
    itemValue: {
        color: theme.color.grayishBlue2
    },
    greyTitle: {
        color: theme.color.grayishBlue3
    },
    checkboxWrapper: {
        cursor: 'pointer',
        marginRight: 16
    },
    ResetPasswordForm: {
        width: '350px',
        margin: 'auto',
        // padding: '30px 0',
        marginTop: '10%',
    },
    form: {
        color: '#434343',
        borderRadius: '1px',
        marginBottom: '15px',
        background: '#fff',
        border: '1px solid #f3f3f3',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
        padding: '30px',
    },
    avatar: {
        color: '#fff',
        margin: '0 auto 30px',
        textAlign: 'center',
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        zIndex: 9,
        background: 'blue',
        padding: '15px',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    },
    forgotPassword: {
        float: 'right'
    }
}));


function ResetPassword(props) {
    const { token } = props.match.params;
    console.log("🚀 ~ file: ResetPassword.js ~ line 64 ~ ResetPassword ~ token", token)
    const classes = useStyles();
    const { user, setUser } = useContext(userContext)
    const [loading, setLoading] = useState(false);
    const ResetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(16, "Maximum 16 symbols")
            .required("Required"),
        confirmPassword: Yup.string()
            .min(8, "Minimum 8 symbols")
            .max(16, "Maximum 16 symbols")
            .required("Required")
            .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";
    };

    useEffect(() => {

        verifyResetToken(token).then((res) => {
        })
            .catch((err) => {
                const errorMessage = err.response.data.errorMessage;
                toast.error(errorMessage, {
                    closeOnClick: true,
                    hideProgressBar: false,
                })
                props.history.push(slugs.login)
            });

    }, [])


    const formik = useFormik({
        initialValues,
        validationSchema: ResetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            console.log("🚀 ~ file: ResetPassword.js ~ line 123 ~ ResetPassword ~ values", values)
            enableLoading();
            resetPassword(token, { password: values.password }).then(res => {
                disableLoading();
                toast.success("Password has been changed successfully!", {
                    closeOnClick: true,
                    hideProgressBar: false,
                })
                props.history.push(slugs.login)

            }).catch((err) => {
                const errorMessage = err.response.data.errorMessage;
                toast.error(errorMessage, {
                    closeOnClick: true,
                    hideProgressBar: false,
                })
                // props.history.push(slugs.login)
            });

        },
    });

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className={classes.ResetPasswordForm}>
                <form
                    onSubmit={formik.handleSubmit}
                    className={classes.form}
                >
                    {formik.status ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    ) : null}

                    <div className="form-group mb-3">
                        <h1 className={classes.title}>Reset password to your account</h1>
                        {/* <div className={classes.avatar}>Logo</div> */}
                        <div className="d-flex justify-content-center align-items-center">

                            <img src="../../assets/img/dose-logo.png" style={{ width: "50%", marginBottom: "20px" }} />
                        </div>

                        <input
                            placeholder="Password"
                            type="password"
                            className={`form-control form-control-solid ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}

                    </div>
                    <div className="form-group mb-3">
                        <input
                            placeholder="Confirm Password"
                            type="password"
                            className={`form-control form-control-solid ${getInputClasses(
                                "confirmPassword"
                            )}`}
                            name="confirmPassword"
                            {...formik.getFieldProps("confirmPassword")}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div>
                                <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                            </div>
                        ) : null}

                    </div>


                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn w-100 text-light`}
                            style={{ background: "#006FD8" }}
                        >
                            {!loading ? 'Reset Password' : <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;
