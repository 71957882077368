import { TableColumn } from 'components/table';
import Loader from 'components/table/Loader';
import Pagination from 'components/table/Pagination';
import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import '../../common/common.css';
import '../../table/table.css';
import { getDoctorMedicineAdherence } from '../../../resources/serverReq/doctor/adherence';

export default function MedicineDocAdherence() {
    let [medicineData, setMedicineData] = useState([]);
    const [perPageRows, setPerPageRows] = useState(7);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [pageData, setPageData] = useState({});
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const params = {
            page: page,
            search: query,
            sort: 1
        };

        getDoctorMedicineAdherence(params)
            .then((response) => {
                setIsLoading(true);
                setDataNotAvailable(false);
                if (response?.data?.code == 200) {
                    const res = response?.data?.data.medicines;
                    setPageData({
                        count: res.count,
                        currentPage: res.currentPage,
                        totalPage: res.totalPage
                    });
                    setMedicineData(res.rows);
                    if (res.count == 0) setDataNotAvailable(true);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                setDataNotAvailable(true);
            });
    }, [query, page]);

    return (
        <>
            {!isLoading ? (
                <div className='table-responsive'>
                    <div className='table-wrapper'>
                        <div className='table-title'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>...</span>
                                <div className='search-box justify-content-end'>
                                    <input
                                        onChange={(event) => setQuery(event.target.value)}
                                        type='text'
                                        value={query}
                                        className='form-control'
                                        placeholder='Search&hellip;'
                                        autoFocus
                                    />
                                </div>
                            </div>
                        </div>
                        <table className='table table-borderless text-center'>
                            <TableColumn columns={['Name', 'Company', 'Count', 'Action']} />
                            <tbody>
                                {dataNotAvailable ? (
                                    <tr>
                                        <td></td>
                                        <td>No Record Found</td>
                                        <td></td>
                                    </tr>
                                ) : (
                                    medicineData.map((el) => (
                                        <tr key={el.id}>
                                            <td>
                                                <p style={{ marginLeft: '15px' }}>{el.name}</p>
                                            </td>
                                            <td>{el.companyName}</td>
                                            <td>{el.count}</td>
                                            <td>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <Link
                                                        to={{
                                                            pathname: `${SLUGS.viewMedicineAdherenceDetails}/${el.id}`,
                                                            state: { title: el.name }
                                                        }}
                                                        style={{ padding: '6px' }}
                                                        className='btn btn-icon btn-light btn-hover-primary btn-sm svg-icon-button'
                                                        title='View Adherence Details'
                                                    >
                                                        <span className='svg-icon menu-icon mr-2 text-light'>
                                                            <FaEye style={{ color: 'black' }} />
                                                        </span>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <Pagination pageData={pageData} setPage={setPage} />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
