import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';


export default function Pagination({ setPage, pageData }) {
    const handleChangePage = (newPage) => {
        const incPage = ++newPage.selected
        setPage(incPage);
    };

    return (
        <>

            <ReactPaginate
                className='d-flex pagination text-dark'
                breakLabel="..."
                nextLabel=" >>"
                onPageChange={handleChangePage}
                pageRangeDisplayed={3}
                pageCount={pageData.totalPage}
                previousLabel="<< "
                renderOnZeroPageCount={null}
                activeLinkClassName = "active-page"
            />
        </>
    );
}
